
//Here's the api connector to communicate with the wordpress gallery endpoint:
//https://localhost/cms-service/index.php/wp-json/gallery/v1/id/45

import { get } from '@kit/utils/APIV1.js'

export const flower = async(slug) => {
  return get(`flower/slug/${slug}`)
}

export const family = async(slug) => {
  return get(`family/slug/${slug}`)
}

export const families = async() => {
  return get(`families`)
}

export const variety = async(slug) => {
  return get(`variety/slug/${slug}`)
}

export const varieties = async() => {
  return get(`varieties`)
}

export const availability = async() => {
  return get(`availability`)
}
<script>
import { defineComponent, computed, onMounted, onBeforeUnmount, ref, inject } from 'vue'
import { pathname } from '@kit/utils/Formats'
import Parallax from '@kit/utils/Parallax'
import LazyImg from '@kit/components/LazyImg.vue'
import ParallaxLoader from '@kit/components/ParallaxLoader.vue'
import Config from '@kit/utils/Config'

/*
Couple things:

It just didn't work to have the LazyImg have its own preloader wheel. So I made ad adhoc component
called ParallaxLoader and gave it a lazy-image-theme. 

Then, owing to how difficult the parallax effect is, the loading style on the lazyImgTheme has to 
match the style, critically the width:100%; height:100%; and all that. Geometry changes mess up
the parallax effect and cause blank spaces to appear.
*/


const defaultConfig = new Config({

  //the classes for the outer-wrapper.
  outerClasses:"sb-spread",

  //these are the options for the image, which is a lazy image
  img: {
    //Notice that we're not using width:100% height:100% or object-fit. This is intentional.
    //instead, we're using the native width and height properties of the image and then just scaling them up 
    //as we need.
    style:"position:absolute; top:0px; left:0px;", 
    loadingStyle:"position:absolute; top:0px; left:0px; object-fit:cover; width:100%; height:100%; opacity:0;",
  },

  //These are the options for the parallax
  parallax: {

    //the type. basic, for now it's the only one.
    type: "basic",

    //this is the setting for if we want the image to be background or not.
    //this is mainly for semantics, but if it's a background image, then there's 
    //some extra options we have for sizing
    //background: null, 
    background: {
      width: 500,
      height: 200,
      size: 'cover',
      position: 'center',
      repeat: 'no-repeat'  
    },
  },

  //These are the options for the loader.
  //The loader is a lazy-image loader config object. See the ParallaxLoader component for details
  loader: {
    loader: {
      wheel: "default",
      fromProject: true,
    },
    //if we're using a background image, we have some more options than an image.
    //We're going to give it a default width and height
    style:"position:absolute; top:0px; left:0px; width:500px; height:200px;",
    loadingStyle:"position:absolute; top:0px; left:0px; object-fit:cover; width:100%; height:100%; opacity:0;",
  }
})


export default defineComponent({
  name: "ParallaxImage",
  
  props: ["src", "theme", "ariaImgLabel"],
  setup(props) {

    const img = ref(null)
    const showLoader = ref(true)
    const themer = inject("themer")

    const { getProp, setProp } = themer({ props, defaultConfig })

    const background = getProp('parallax.background')

    //if we're using the background settings, then we're going to 
    //create a new style string for the img.style
    if(background) {
      const { width, height, size, position, repeat } = background
      setProp('img.style', `width:${width}px; height:${height}px; background-size:${size}; background-position:${position}; background-repeat:${repeat}; position:absolute; top:0px; left:0px;`)
    } 

    let sP = null

    const lazyImageWasLoaded = () => {
      showLoader.value = false
      Parallax.screenResized()
    }

    onMounted(() => {
      if(sP == null) {
        sP = new Parallax()
        const ops = getProp("parallax")
        sP.register(img.value.$el, ops)
      }
    })
    onBeforeUnmount(() => {
      if(sP) {
        sP.destroy()
        sP = null
      }
    })

    return { pathname, img, showLoader, lazyImageWasLoaded, getProp, setProp }
  },
  components: {
    LazyImg,
    ParallaxLoader
  }
});
</script>

<template>
  <div :class="getProp('outerClasses')" style="overflow:hidden;">
    <LazyImg :background="!!getProp('parallax.background')" @lazyImgLoaded="lazyImageWasLoaded" ref="img" :theme="getProp('img', 'theme')" :ariaLabel="ariaImgLabel" :src="`${pathname(src)}`"/>
    <ParallaxLoader v-if="showLoader" :theme="getProp('loader', 'theme')"/> 
  </div>
</template>
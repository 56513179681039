import { sunriseProteaRolloverImg } from "@project/theme-lazyimg"

export const sunriseProteaFamilyRolloverInfoImg = {
  outerClasses:"sb-v aboutus-rollover-area sb-explicit sb-gr sb-g10",
  outerCSS:"padding:0px;",
  coverType:"funky",
  coverCSS: `background-color:rgba(0,0,0,0.4)`, 
  coverRows:5,
  coverCols:5,
  onContentClasses: "sb-v sb-alt2 sb-align-c sb-g20",
  offContentClasses: "sb-v sb-align-2bottom sb-gr sb-g20 srp-RolloverInfoImg-offContent",
  imgAriaLabel:"sunrise protea picture",
  imgTheme: sunriseProteaRolloverImg
}
import { home } from '@project/api/home/v1'
import { flower, family, families, availability, variety, varieties } from '@project/api/flowers/v1'
import { page as contactUsPage } from '@project/api/contact-us/v1'
// import { aboutUs } from '@project/api/about-us/v1'
// import { services } from '@project/api/services/v1'

export default {
  async home() {
    return await home()
  },
  async flower(slug) {
    return await flower(slug)
  },
  async family(slug) {
    return await family(slug)
  },
  async families() {
    return await families()
  },
  async variety(slug) {
    return await variety(slug)
  },
  async varieties() {
    return await varieties()
  },
  async availability() {
    return await availability()
  },
  async contactUs() {
    return await contactUsPage()
  },
  // async aboutUs() {
  //   return await aboutUs()
  // },
}


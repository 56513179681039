import { createStore } from 'vuex'
import { modifyState, modifyMutations, modifyActions } from '@project/modify/store'
import { AUTH_TYPE_WPJWT, AUTH_LOGIN_STATE_COLD, AUTH_LOGIN_STATE_TRUE, AUTH_LOGIN_STATE_FALSE } from '@/kit/utils/auth/Auth'

export const store = createStore({
  state () {
    return {
      drawerOut: false,
      drawerMotion: false,
      drawerOutImmediate: false,
      inMobile: false,
      loggedIn: AUTH_LOGIN_STATE_COLD,
      authType: '',
      userInfo: null,
      ...modifyState()
    }
  },
  mutations: {
    setDrawerOut(state, inOrOut) {
      state.drawerOut = inOrOut
    },
    setDrawerOutImmediate(state, inOrOut) {
      state.drawerOutImmediate = inOrOut
    },
    setDrawerMotion( state, trueOrFalse) {
      state.drawerMotion = trueOrFalse
    },
    setScreenMobile(state, inMobile) {
      state.inMobile = inMobile
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn
    },
    setAuthType(state, authType) {
      state.authType = authType
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    ...modifyMutations()
  },
  actions: {
    screenThreshold({commit}, inMobile) {
      commit("setScreenMobile", inMobile)
    },
    wpJWTLoggedIn({commit}, userInfo) {
      commit("setAuthType", AUTH_TYPE_WPJWT)
      commit("setUserInfo", userInfo)
      commit("setLoggedIn", AUTH_LOGIN_STATE_TRUE)
    },
    wpJWTLoggedOut({commit}) {
      commit("setUserInfo", null)
      commit("setLoggedIn", AUTH_LOGIN_STATE_FALSE)
    },
    ...modifyActions()
  }
  
})

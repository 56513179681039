<script>
import { defineComponent, inject, computed } from "vue"
import NavMainSearch from "@kit/components/NavMainSearch.vue"
import { searchWidgetMain, navbar } from "@project/themes"
import NavBarBase from "@kit/components/NavBarBase.vue"
import IconButton from "@kit/components/IconButton.vue"



export default defineComponent({
  name: "NavBar",
  setup() {
    const hydrate = inject('hydrate')

    const { prefetch } = hydrate({
      prefetch:['families','varieties']
    })

    const themer = inject('themer')
    const navbarTheme = themer(navbar)
    const searchWidgetMainTheme = themer(searchWidgetMain)

    return { searchWidgetMainTheme, navbarTheme, prefetch };
  },
  components: {
    NavMainSearch,
    NavBarBase,
    IconButton
  },
});
</script>

<style>

/*-------------*\
   Navbar CSS 
\*-------------*/

/* .navbar-subitems {
  background-color: ;
} */

.ics-logo-wrapper {
  padding:5px;
}

.navbar-subitems > .srp-navlink {
  color: var(--text-regular);
  font-weight: normal;
  text-decoration: none; 
  background-color: #ebbe95;
}
.navbar-subitems > .srp-navlink:hover {
  background-color: #ebb17a;
}
.navbar-subitems > .router-link-exact-active.srp-navlink {
  color: var(--text-over-dark);
  font-weight: bold;
  text-decoration: none; 
  background-color:#cc5800;
}

.navbar-subitems > .router-link-exact-active.srp-navlink:hover {
  background-color:rgb(185, 66, 15);
}

/* Make sure that the wrapper has a width or this will be messed up in mobile. */
/* .navbar-logo-wrapper {
 width:100px;
} */

.ics-navbar-boxshadow {
  box-shadow: 5px 5px 10px rgba(0,0,0, 0.5);
}
.ics-navbar-boxshadow--attop {
  box-shadow: 5px 5px 10px rgba(0,0,0, 0.5);
}

.ics-nav-hamburger-nav-button--attop, .ics-nav-hamburger-nav-button  {
  margin-top:10px; 
  margin-bottom:10px;
  margin-right:20px; 
  width: 50px;
  height:30px;
  cursor:pointer;
}
.ics-nav-hamburger-nav-button {
  border-top:4px solid #444444; 
  border-bottom:4px solid #444444;
}
.ics-nav-hamburger-nav-button--attop::after, .ics-nav-hamburger-nav-button::after {
  content:"";
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  height:4px;
  left:0;
  right:0;
}
.ics-nav-hamburger-nav-button::after {
  background: #555555;
}


.nav-top-option {
  cursor: pointer;
  padding:20px;
  z-index:2;
}

.navbar-subitems > a, 
.navbar-subitems > a.router-link-exact-active {
  padding:10px 15px 10px 15px;
}

.navbar-subitems--attop > a, 
.navbar-subitems--attop > a.router-link-exact-active {
  padding:10px 15px 10px 15px;
}


.srp-navlink {
  color: var(--text-regular);
  font-weight: normal;
  text-decoration: none; 
}
.router-link-exact-active.srp-navlink {
  color: var(--text-regular);
  font-weight: bold;
  text-decoration: none; 
}

.ics-nav-main-searchoption {
  width:100px !important;
}
.ics-nav-main-search-iconbutton {
  width:40px !important;
}
.ics-nav-main-search-widget-button {
  width:100px !important;
}


/*-------------*\
   Search UI CSS 
\*-------------*/


/* Search UI CSS */
.ics-search-modal-window {
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  min-height: 335px;
  padding-top: 107px;
  padding-bottom: 50px;
  background-color: rgba(6, 42, 70, 0.92);
  background-image: linear-gradient(257deg, rgba(19, 95, 129, 0.68), rgba(6, 42, 70, 0.73));
  opacity: 1;
}

.ics-search-results-outer h1 {
  font-size:1.5rem;
}

.ics-main-search-widget-headline {
  font-size:1.2em;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}
.ics-mobile-search-widget-headline {
  font-size:1.5em;
  color:white;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}

input.nav-mobile-search-input:focus, input.nav-main-search-input:focus, input.nav-main-search-nonmodal-input:focus  {
  background-color:#e6ebe1 !important;
}

input.nav-mobile-search-input, input.nav-main-search-input, input.nav-main-search-nonmodal-input {
  border-style: none none solid;
  background-color: #EEEEEE;
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding:10px !important;
}
input.nav-main-search-nonmodal-input {
  /* border:1px solid #CCCCCC; */
  /* border-bottom: 2px solid #516b38; */
  border-radius: 3px;
}
@media (max-width : 960px) {
  input.nav-mobile-search-input {
    font-size: 25px;
  }
}

.nav-main-search-ui-container {
  background-color:white;
  border-radius:5px;
}
.nav-mobile-search-ui-container {
  background-color:white;
  border-radius:5px;
}



.nav-main-search-nonmodal-completion-ui-container {
  box-shadow: 0px 5px 5px rgba(0,0,0, 0.5); 
}

/*modal styles*/
.ics-search-results-prevnext-wrapper {
  padding:0px !important;
  margin-top:10px !important;
}
.ics-search-result {
  padding: 0px !important;
}
.ics-results-block {
  padding:10px !important;
  background: #F2F2F2;
}


/*nonmodal widget*/
.ics-search-modal-search-nonmodal-button {
  width: 50px !important;
}
.ics-search-nonmodal-headline {
  font-weight:400;
  color: var(--dark-text);
}

/*styles for the mobile side-nav drawer ui*/
.ics-mobile-search-button {
  width: 50% !important;
}
.ics-mobile-search-button svg {
  transform: rotate(45deg);
}
.ics-mobile-search-headline {
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  color:white; 
}
.ics-mobile-search-wrapper {
  padding-top:20px;
  padding-right:20px;
}
.ics-mobile-search-completion-wrapper, .ics-main-search-completion-wrapper  {
  color:black;
  font-size: 20px;
}
.ics-mobile-completion-result {
  padding:7px;
}
.ics-main-completion-result {
  padding:7px;
  font-size: 18px;
}
.ics-mobile-completion-result:hover, .ics-main-completion-result:hover  {
  background-color:#EEEEEE;
}

</style>

<template>
  <NavBarBase :theme="navbarTheme">
    <template v-slot:nav-items="{ navItemClasses, dropDownNavItemClasses, navSubItemClasses }">

          <div :class="navItemClasses">
            <router-link class="sb srp-navlink" to="/">Home</router-link><i></i>
          </div>
    
          <div :class="dropDownNavItemClasses">
            Families<i></i>
            <div v-if="prefetch && prefetch.families" :class="navSubItemClasses">
              <template v-for="family in prefetch.families">
                <router-link class="sb srp-navlink" style="white-space:nowrap;" :to="`/family/${family.url_slug}`"><span>{{family.display_title}}</span><i class="sb"></i></router-link>
              </template>
            </div>
          </div>

          <div :class="dropDownNavItemClasses">
            Varieties<i></i>
            <div v-if="prefetch && prefetch.varieties" :class="navSubItemClasses">
              <template v-for="variety in prefetch.varieties">
                <router-link class="sb srp-navlink" style="white-space:nowrap;" :to="`/variety/${variety.url_slug}`"><span>{{variety.display_title}}</span><i class="sb"></i></router-link>
              </template>
            </div>
          </div>

          <div :class="navItemClasses">
            <router-link class="sb srp-navlink" to="/availability">Availability</router-link><i></i>
          </div>

          <!-- <div :class="navItemClasses">
            <router-link class="sb srp-navlink" to="/plant-sales">Plant Sales</router-link><i></i>
          </div> -->

          <!-- <div :class="navItemClasses">
            <router-link class="sb srp-navlink" to="/about">About Us</router-link><i></i>
          </div> -->

          <div :class="navItemClasses">
            <router-link class="sb srp-navlink" to="/contact-us">Contact Us</router-link><i></i>
          </div>

          <!-- <NavMainSearch ref="navMainSearch" :theme="searchWidgetMainTheme" /> -->
          
    </template>
  </NavBarBase>

</template>

import { slugs } from '@project/api/sitemap/v1'

export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "home" */'@pages/Home.vue')
  },
  {
    name: 'family',
    path: '/family/:slug',
    component: () => import(/* webpackChunkName: "family" */'@pages/Family.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('flower_family')
        }
      }
    }
  },
  {
    name: 'variety',
    path: '/variety/:slug',
    component: () => import(/* webpackChunkName: "variety" */'@pages/Variety.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('variety')
        }
      }
    }
  },
  {
    name: 'flower',
    path: '/flower/:slug',
    component: () => import(/* webpackChunkName: "flower" */'@pages/Flower.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await slugs('flower')
        }
      }
    }
  },
  {
    name: 'availability',
    path: '/availability',
    component: () => import(/* webpackChunkName: "availability" */'@pages/Availability.vue')
  },
  {
    name: 'contact',
    path: '/contact-us',
    component: () => import(/* webpackChunkName: "contact" */'@pages/Contact.vue')
  },
]
//////////////////////////////////////////////////////////////////////////////////
//                                                                              //                   
//  A helper for getting the meta-data out of the serverPrefetch hooks and      //
//  into the entry-server where it can be injected into the render and          //
//  output the meta-data to the page                                            //
//                                                                              //
//  This is supposed to be possible with vue-meta, but examples in              //
//  conflicting/contradictory documentation around this topic just don't work   //
//  so we're doing this solution instead.                                       //
//                                                                              //
//  Author Alex Lowe                                                            //
//                                                                              //
//////////////////////////////////////////////////////////////////////////////////

import { getFullURL } from '@kit/utils/EnvironmentHelper'


class Meta {

  static extraAtts(preloadFile) {
      let atts = []
      const keys = Object.keys(preloadFile) 
      for(let i=0; i<keys.length; i++) {
        const k = keys[i]
        if(k != "src") {
          const v = preloadFile[k]
          atts.push(`${k}="${v}"`)
        }
      }
    return " "+atts.join(" ")
  } 
  static jsModToString = (src, extraAtts) => {
    return `<link rel="modulepreload" crossorigin href="${src}"${extraAtts}>`
  }
  static jsBodyToString = (src, code, extraAtts) => {
    return code ? `<script ${extraAtts}type="text/javascript">${code}</script>` : `<script src="${src}" crossorigin${extraAtts}></script>` 
  }
  static jsHeadToString = (src, code, extraAtts) => {
    return code ? `<script ${extraAtts}type="text/javascript">${code}</script>` : `<script src="${src}" crossorigin${extraAtts}></script>` 
  }
  static cssToString = (src, extraAtts) => {
    return ` <link rel="stylesheet" href="${src}"${extraAtts}>` 
  } 
  static woffToString = (src, extraAtts) => {
    return ` <link rel="preload" href="${src}" as="font" type="font/woff" crossorigin${extraAtts}>`
  }
  static woff2ToString = (src, extraAtts) => {
    return ` <link rel="preload" href="${src}" as="font" type="font/woff2" crossorigin${extraAtts}>`
  }
  static gifToString = (src, extraAtts) => {
    return ` <link rel="preload" href="${src}" as="image" type="image/gif"${extraAtts}>`
  }
  static jpgToString = (src, extraAtts) => {
    return ` <link rel="preload" href="${src}" as="image" type="image/jpeg"${extraAtts}>`
  } 
  static pngToString = (src, extraAtts) => {
    return ` <link rel="preload" href="${src}" as="image" type="image/png"${extraAtts}>`
  } 


  /**
   * @param {object} hydrationContext Required. The context object provided by the hydrationContext function from the Hydrate utility.
   */
  constructor(hydrationContext) {

    this.hydrationContext = hydrationContext

    this.metaContext = {}
    this.currentPriority = 0
    this.fullURL = ''
    this.duplicateGuard = {
      htmlAttrs: {},
      headAttrs: {},
      bodyAttrs: {},
      appAttrs: {},
      meta: {}
    }
  }


  /**
   * @param {string} which Required. The name of the tag for which you want to render a list of attributes. 
   * @returns {string} The attibutes for the tag. This is used in SSR-mode on the server to put together the attributes 
   *    during the rendering of the page.
   */
  _renderAtts(which) {
    const atts = this.metaContext[which] || []
    const lm1 = atts.length-1
    const dupes = {}
    const out = []

    //loop backward so that the most recent ones override the
    //earlier ones.
    for(let i=lm1; i>=0; i--) {
      const att = atts[i]
      const { name, value } = att
      if(!dupes[name]) {
        dupes[name]=true
        out.push(`${name}="${this._cleanContent(value,"")}"`)
      }
    }

    return out.reverse().join(" ")
  }


  /**
   * @param {string} which Required. Which tag do you want to add an attribute to? head, body, html
   * @param {object} obj Required. the object representing the attribute that you want to add 
   * 
   */
  _addAttribute(which, obj) {
    const { metaContext } = this

    if(!metaContext[which]) {
      metaContext[which] = []
    }

    const atts = metaContext[which]
    let arr = null

    if(obj.constructor === Array) {
      arr = obj
    } else 
    if(obj.constructor === Object) {
      arr = [obj]
    }

    const l = arr.length 
    for(let i=0; i<l; i++) {
      const att = arr[i]
      atts.push(att)
    }

  }


  /**
   * Sets the priority which determines metadata de-duplicating
   * 
   * @param {*} priority 
   */
  setPriority(priority) {
    this.currentPriority = priority+1
  }


  /**
   * A signal from Hydrate that it's time to record the full url from the environment.
   * Vue is picky about when we do this. something something setup function
   * 
   */
  recordFullURL(url) {
    this.fullURL = url || getFullURL()
  }


  /**
   * @param {object} metaContext Required. The metaContext
   * @param {object} duplicateGuard Required. The duplicateGuard
   * 
   * This fires on first-page-load in SSR on the client-side.
   * The server puts together these objects, attaches them to the page data in the html 
   * and then sends the whole mess to the client. The client picks up the page, the browser
   * happily renders the html and then the app in the entry-client grabs these values from
   * the page data and then sets them on the shared Meta instance using this method. This
   * is so that the app can go on functioning after first-page-load.
   * 
   */
  setData(metaContext, duplicateGuard) {
    this.metaContext = metaContext
    this.duplicateGuard = duplicateGuard
  }


  /**
   * @param {string} unclean Optional. The unclean string 
   * @param {*} _default Optional. The value to return in the event that the unclean string is falsy.
   * @returns clean string
   * 
   * Sanitize a string so that we use it in the html attributes of the tags.
   * 
   */
  _cleanContent(unclean, _default) {
    if(!unclean) {
      return _default
    } else {
      return unclean.replace(/"/g, '&quot;').replace(/\s+/g, " ")
    }
  }


  /**
   * 
   * @param {String} item 
   * @returns The title tag
   */
  _renderHeadStringTitle(item) {
    if(!item) {
      return ''
    } 
    return `<title>${item}</title>`
  }


  /**
   * @param {String} item 
   * @returns canonical tag
   */
  _renderCanonicalLink(item) {
    if(!item) {
      return ''
    }
    return `<link rel="canonical" href="${item}" />`
  }


  /**
   * Render all of the meta tags
   * 
   * @param {Array} metaItems. Array of meta-items to render 
   * @returns 
   */
  _renderHeadStringMetaTags(metaItems) {
    if(!metaItems) {
      return ''
    }

    let renderedItem = ''
    const metaItemsKeys = Object.keys(metaItems) 
    const l = metaItemsKeys.length

    for(let i=0; i<l; i++) {
      const mItemKey = metaItemsKeys[i]
      const mItem = metaItems[mItemKey]
      const mContent = this._cleanContent(mItem.content, "")

      if(mItem.name) {
        renderedItem += `<meta name="${mItem.name}" content="${mContent}"/>`
      } else 
      if(mItem.property) {
        renderedItem += `<meta property="${mItem.property}" content="${mContent}"/>`
      } else 
      if(mItem["http-equiv"]) {
        renderedItem += `<meta http-equiv="${this._cleanContent(mItem['http-equiv'])}" content="${mContent}"/>`
      } else 
      if(mItem.charset) {
        renderedItem += `<meta charset="${this._cleanContent(mItem.charset)}"/>`
      }
    }

    return renderedItem
  }

  _renderImageSrc(file) {
    return file.startsWith('@images/') ? file.replace('@images/', 'assets/') : file
  }

  /**
   * @param {Array} preloadFiles
   * @returns html tags for the preload files files
   * 
   */
  _renderHeadStringPreloadTags(preloadFiles) {
    let output = ''

    if(!preloadFiles) {
      return output
    }
    const all = preloadFiles.all || []
    const dups = {}

    for(let i=0; i<all.length; i++) {
      const ith = all[i]
      const { src, render } = ith
      if(!dups[src]) {
        output += render
        dups[src] = true
      }
      delete ith.render
    }

    return output
  }

  /**
   * @param {Array} extraJSFiles 
   * @returns html tags for the extra-js files meant for the head
   * 
   */
  _renderHeadStringExtraJS(extraJSFiles) {
    let output = ''

    if(!extraJSFiles) {
      return output
    }

    const head = extraJSFiles.head || []

    const dups = {}

    for(let i=0; i<head.length; i++) {
      const ith = head[i]
      const { src, render } = ith

      //if it has a src, then use that src for de-duplication
      if(src) {
        if(!dups[src]) {
          output += render
          dups[src] = true
        }
      }
      //else, it has no src, so we assume that it's just an
      //inline script that has to get put out and we assume that 
      //it's not a duplicate
      else {
        output += render
      }
      delete ith.render
    }

    return output
  }


  /**
   * @param {Array (optional)} externalCSS 
   * @returns link tags for external css that we want to include in the header
   * 
   */
  _renderHeadStringExternalCSS(externalCSS) {
    let output = ''
    let addWebfontPreconnect = false
    if(!externalCSS) {
      return output
    }
    for(let i=0; i<externalCSS.length; i++) {
      const ith = externalCSS[i]

      if(typeof ith === 'string') {
        output += `<link type="text/css" data-xcss="1" rel="stylesheet" href="${ith}">`
      } else {

        const { type, href } = ith
        if(!type) {
          throw new Error("Expecting type in this externalCSS object "+JSON.stringify(ith))
        } 
        if(type == "webfont") {
          addWebfontPreconnect = true
          let href2 = `${href}&display=swap`
          output += `<link rel="stylesheet" data-xcss="1" media="print" onload="this.media='all'" href="${href2}">`
          //preload doesn't work quite as well. There will probably be a better way of handling this in the future.
          //output += `<link rel="preload" data-xcss="1" as="style" href="${href2}">`
        } else 
        if(type == "css") {
          output += `<link rel="stylesheet" data-xcss="1" media="print" onload="this.media='all'" href="${href}">`
        } else {
          throw new Error("Expecting type to be either 'webfont' or 'css'")
        }

      }

      //output += `<link type="text/css" rel="stylesheet" href="${ith}">`
      //use the preload rel for faster css. I wish the web community would make up
      //its mind one of these days
      //No, this doesnt work for webfonts
      //output += `<link type="text/css" data-xcss="1" rel="preload" as="style" href="${ith}">`

      //output += `<link type="text/css" data-xcss="1" rel="stylesheet" href="${ith}">`
    }

    if(addWebfontPreconnect) {
      output = `<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />${output}`
      output = `<link rel="preconnect" href="https://fonts.googleapis.com" />${output}`
    }

    return output
  }


  /**
   * @param {Array} preloads. Required. array of file uris
   * @returns object with files sorted by type.
   * 
   */
  _sortPreloads(preloads) {

    const js = []
    const all = []
    const images = []
    const fonts = []
    const css = []

    for(let i=0; i<preloads.length; i++) {

      const _preload = preloads[i]
      const isObj = _preload.constructor == Object
      const preloadObj = isObj ? _preload : { src:_preload } 
      const { src } = preloadObj
      const last3 = src.slice(-3)
      const last4 = src.slice(-4)

      let extraAtts = ''
      if(isObj) {
        let atts = []
        const keys = Object.keys(preloadObj) 
        for(let i=0; i<keys.length; i++) {
          const k = keys[i]
          if(k != "src") {
            const v = preloadObj[k]
            atts.push(`${k}="${v}"`)
          }
        }
        extraAtts = " "+atts.join(" ")
      }


      //If it's a javascript file, then it's a little more complicated.
      //TODO: review this preload js module. This might not be the best way to do this.
      if (last3 == '.js') {
        preloadObj.render = Meta.jsModToString(src, extraAtts)
        js.push(preloadObj)
      } else 
      if(last4 == '.png') {
        const src2 = this._renderImageSrc(src)
        preloadObj.render = Meta.pngToString(src2, extraAtts)
        images.push(preloadObj)
      } else 
      if(last4 == ".gif") {
        const src2 = this._renderImageSrc(src)
        preloadObj.render = Meta.gifToString(src2, extraAtts)
        images.push(preloadObj)
      } else 
      if(last4 == ".jpg") {
        const src2 = this._renderImageSrc(src)
        preloadObj.render = Meta.jpgToString(src2, extraAtts)
        images.push(preloadObj)
      } else 
      if(last4 == "jpeg") {
        const src2 = this._renderImageSrc(src)
        preloadObj.render = Meta.jpgToString(src2, extraAtts)
        images.push(preloadObj)
      } else 
      if(last4 == '.css') {
        preloadObj.render = Meta.cssToString(src, extraAtts)
        css.push(preloadObj)
      } else 
      if(last4 == 'woff') {
        preloadObj.render = Meta.woffToString(src, extraAtts)
        fonts.push(preloadObj)
      } else 
      if(last4 == 'off2') {
        preloadObj.render = Meta.woff2ToString(src, extraAtts)
        fonts.push(preloadObj)
      } else {
        //TODO
      }
      
      all.push(preloadObj)
      
    }

    return { images, fonts, css, js, all }
  }


  /**
   * @param {Array} extraJS 
   * @returns an object of the extra js sorted by type: either scripts to include at the end of the body 
   *   or scripts to include in the head section
   * 
   */
  _sortExtraJS(extraJS) {

    const all = []
    const head = []
    const body = []
    const dedup = {}

    for(let i=0; i<extraJS.length; i++) {

      const _xJS = extraJS[i]
      const isObj = _xJS.constructor == Object
      const xjsObj = isObj ? _xJS : { src:_xJS, code:null } 
      let { src, code } = xjsObj
      const forHead = !!xjsObj.head
      const proxy = xjsObj.proxy || false

      const dedupKey = src || code
      dedup[dedupKey] = true
      
      //If it's a proxy, then the proxy string is going to be the type of proxy.
      //Right now the only one is "google-cse". There will probably be others in the future.
      //If we're using the proxy, then the src property from the meta object is going to 
      //interpreted as a list of url parameters. Each proxy location has different sets of 
      //url parameters, and those are mapped from the parameters in the src string, 
      //e.g. the src string: google_cse_id=48eucue922 is used for the "google-cse" proxy, and 
      //the url parameter google_cse_id will be used for putting together the final url within
      //the proxy endpoint.
      let srcURL = ''
      if(proxy) {
        srcURL = `/proxy?type=${proxy}&${src}`
      } else {
        srcURL = src
      }

      let extraAtts = ''
      if(isObj) {
        let atts = []
        const keys = Object.keys(xjsObj) 
        for(let i=0; i<keys.length; i++) {
          const k = keys[i]
          if(k != "src" && k != "proxy" && k != "code" && k != "head" && k != "render") {
            const v = xjsObj[k]
            atts.push(`${k}="${v}"`)
          }
        }
        extraAtts = " "+atts.join(" ")
      }

      if (forHead) {
        xjsObj.render = Meta.jsHeadToString(srcURL, code, extraAtts)
        head.push(xjsObj)
      } else {
        xjsObj.render = Meta.jsBodyToString(srcURL, code, extraAtts)
        body.push(xjsObj)
      }

      all.push(xjsObj)      
    }

    return { head, body, all, dedup }
  }


  /**
   * @param {*} obj Required. A dom-node or an object 
   * @param {*} key the key. either to an attribute or kvp
   * @param {*} virtual is the obj parameter a dom-node?
   * 
   * @returns the value for the key
   */
  getAttribute(obj, key, virtual) {
    if(!virtual) {
      return obj.getAttribute(key)
    } else {
      return obj[key]
    }
  }


  /**
   * @param {*} obj Required. A dom-node or an object 
   * @param {*} key the key. either to an attribute or kvp
   * @param {*} virtual is the obj parameter a dom-node?
   * 
   */
  setAttribute(element, key, _value) {
    const value = this._cleanContent(_value, "")
    element.setAttribute(key, value)
  }


  /**
   * We have these three kinds of tags to support in this thing:
   * 
   * <meta charset="utf-8"/>
   * <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
   *
   *
   * @param {object} obj Required. either a dom-node, or a new-metadata object
   * @param {boolean} virtual Required. is this a dom-node or an object from the new-metadata?
   * @returns the type of metadata tag, the value for that type, and then the content 
   * 
   * 
   */
  keyValueContent(obj, virtual) {
    let key, value, content = null

    let name, httpEquiv, property, charset = null

    if(name = this.getAttribute(obj, "name", virtual)) {
      key = "name" 
      value = name
    } else 
    if(property = this.getAttribute(obj, "property", virtual)) {
      key = "property"
      value = property
    } else 
    if(charset = this.getAttribute(obj, "charset", virtual)) {
      key = "charset"
      value = charset
    } else 
    if(httpEquiv = this.getAttribute(obj, "http-equiv", virtual)) {
      key = "http-equiv"
      value = httpEquiv
    }
    content = this._cleanContent(this.getAttribute(obj, "content", virtual), "")

    const returnVal = { key, value, content }

    return returnVal
  }


  //Update the dom for the external js dependencies.
  async updateExternalJS(xJS) {
    if(!xJS) {
      return 
    } 

    if(!this.metaContext.extraDedup) {
      this.metaContext.extraDedup = {}
    }

    const dedup = this.metaContext.extraDedup
    const{ head, body } = xJS 

    for(let i=0; i<head.length; i++) {
      const xjs = head[i]
      const key = xjs.src || xjs.code
      if(!dedup[key]) {
        dedup[key] = true
      }
    }
    for(let i=0; i<body.length; i++) {
      const xjs = body[i]
      const key = xjs.src || xjs.code
      if(!dedup[key]) {
        dedup[key] = true
        await this.updateExternalJSHandleXJS(xjs, true)
      }
    }
    for(let i=0; i<head.length; i++) {
      const xjs = head[i]
      const key = xjs.src || xjs.code
      if(!dedup[key]) {
        dedup[key] = true
        await this.updateExternalJSHandleXJS(xjs, false)
      }
    }
    

  }

  /**
   * 
   * @param {xjs object, required} XJS. 
   *  The xjs object which has at the least the code, src and proxy properties.
   *  Create the script element, make it asynchronous.
   *  https://www.danielcrabtree.com/blog/25/gotchas-with-dynamically-adding-script-tags-to-html
   *  the load event is fired also in the event of a javascript error, including a 404.
   *  You'll see this kind of thing in the event that something went wrong:
   *  Uncaught SyntaxError: Unexpected token '<'
   * 
   * @param {Boolean, required} isBody.
   *  If true, then it will append to the body. Else, the head.
   * 
   * Note that for scripts with src, we set async to true, and we return 
   * a promise. IF given the choice between sync vs async always seems like you're 
   * better off going with async
   * 
   */
  async updateExternalJSHandleXJS({ code, src, proxy, main, async }, isBody) {
    const targetLocation = isBody ? document.body : document.head
    const scriptEl = document.createElement("script") 
    let loadPromise = null

    if(src) {
      let srcURL = ''
      if(!proxy) {
        //same as "anonymous"
        scriptEl.setAttribute("crossorigin","")
        srcURL = src
      } else {
        srcURL = `/proxy?type=${proxy}&${src}`
      }
      loadPromise = new Promise((resolve) => {
        scriptEl.addEventListener("load", () => {
          if(main) {
            window[main]()
          }
          resolve()
        })
      })
      scriptEl.setAttribute("src", srcURL)   
      if(async) {
        scriptEl.setAttribute("async","true")  
      }      
    } else {
      const inlineScript = document.createTextNode(code);
      scriptEl.setAttribute("type","text/javascript")
      scriptEl.appendChild(inlineScript)
    }

    targetLocation.appendChild(scriptEl)

    return loadPromise

  }


  updateExternalCSS(linkEls, head, newExternalCSS) {

    if(!newExternalCSS) {
      return
    }

    const oldLen = linkEls.length
    const newLen = newExternalCSS.length

    if(newLen >= oldLen) {
      for(let i=0; i<newLen; i++) {
        const newHref = newExternalCSS[i]
        
        if(i < oldLen) {
          const el = linkEls[i]
          if(el.getAttribute("href") != newHref) {
            el.setAttribute("href", newHref)
          } 
        } else {
          const el = document.createElement("link")
          el.setAttribute("href", newHref)
          el.setAttribute("type","text/css")
          head.appendChild(el)
        }
      }
    } else {
      for(let i=0; i<oldLen; i++) {
        const oldEl = linkEls[i]
        
        if(i < newLen) {
          const newHref = newExternalCSS[i]
          if(oldEl.getAttribute("href") != newHref) {
            oldEl.setAttribute("href", newHref)
          } 
        } else {
          head.removeChild(oldEl)
        }
      }
    }

  }


  /**
   * @param {*} head Required. The head DOM element
   * @param {*} newMetaItemsObj Required. the new metadata items
   * 
   * Update the dom elements in the head using a not-totally-naive add-edit-remove process, i.e.
   * don't just clear it out and rebuild it from scratch every time.
   * 
   */
  updateMetadata(head, newMetaItemsObj) {

    const children = head.children
    const oldLen = children.length
    const newMetaItems = Object.keys(newMetaItemsObj)
    const newLen = newMetaItems.length

    const removeFromElement = []
    const addToElement = []


    //mark for removal, and edit in place.
    for(let i=0; i<oldLen; i++) {
      const child = children[i]
      const nName = child.nodeName.toLowerCase()

      if(nName == "meta") {
        const { key:oldKey, value:oldValue, content:oldContent} = this.keyValueContent(child, false)
        
        let oldFoundInNew = false 
        for(let j=0; j<newLen; j++) {
          const newItemKey = newMetaItems[j]
          const newItem = newMetaItemsObj[newItemKey]
          const { key:newKey, value:newValue, content:newContent} = this.keyValueContent(newItem, true)

          //Edit the old one
          if(newKey == oldKey && newValue == oldValue) {

            if(newContent) {
              if(newContent != oldContent) {
                this.setAttribute(child,"content",newContent)
              }
            } else {
              child.removeAttribute("content")
            }

            oldFoundInNew = true
            break
          }

        }      

        //remove the old one if it is not used.
        if(!oldFoundInNew) {
          removeFromElement.push(child)
        }

      }

    }

    
    //mark for addition
    for(let i=0; i<newLen; i++) {
      const newItemKey = newMetaItems[i]
      const newItem = newMetaItemsObj[newItemKey]
      let newFoundInOld = false
      const { key:newKey, value:newValue, content:newContent} = this.keyValueContent(newItem, true)

      for(let j=0; j<oldLen; j++) {
        const child = children[j]
        if(child.nodeName == "META") {
          const { key:oldKey, value:oldValue, content:oldContent} = this.keyValueContent(child, false)
          if(newKey == oldKey && newValue == oldValue && newContent == oldContent ) {
            newFoundInOld = true
            break
          }
        }
      }

      //push to the addition list
      if(!newFoundInOld) {
        addToElement.push(newItem)
      }

    }


    //perform adds and removes
    for(let i=0; i<removeFromElement.length; i++) {
      const r = removeFromElement[i]
      head.removeChild(r)
    }
    for(let i=0; i<addToElement.length; i++) {
      const addItem = addToElement[i]
      const { key, value, content} = this.keyValueContent(addItem, true)
      const a = document.createElement("meta")
      this.setAttribute(a, key, value )
      if(content) {
        this.setAttribute(a, "content",content )
      }    
      head.appendChild(a)
    }

  }


  /**
   * update the attributes. This is will reconcile the old attributes. Remove the ones that unneeded,
   * add new ones in and edit the ones that overlap
   * 
   */
  updateAttributes(element, newAtts) {

    const oldAtts = element.attributes;
    let oldLen = oldAtts.length;
    const newLen = newAtts.length;

    for (let i = 0; i < oldLen; i++) {
      const oldAtt = oldAtts[i];
      const { name: oldName } = oldAtt;
      let keepOldAtt = false;

      for (let j = 0; j < newLen; j++) {
        const newAtt = newAtts[j];
        const { name: newName } = newAtt;

        //of the old and new overlap, then edit the old one
        if (newName === oldName) {
          this.setAttribute(element, newAtt.name, newAtt.value )
          keepOldAtt = true;
          break;
        }
      }

      //if the old attribute was not found among the new attributes, then get rid of it.
      if (!keepOldAtt) {
        //don't remove id attributes. We need those to stay. This is for the
        //id of the root div element where the app is mounted.
        if(oldAtt.name != "id") {
          element.removeAttribute(oldAtt.name);
          i--;
          oldLen--;
        }
      }
    }

    for (let i = 0; i < newLen; i++) {
      const newAtt = newAtts[i];
      const { name: newName } = newAtt;
      let addNewAtt = true;

      for (let j = 0; j < oldLen; j++) {
        const oldAtt = oldAtts[j];
        const { name: oldName } = oldAtt;

        //of the old and new overlap, then edit the old one
        if (newName === oldName) {
          addNewAtt = false;
          break;
        }
      }

      //if the new attribute was not found among the old attributes, then add it..
      if (addNewAtt) {
        this.setAttribute(element, newAtt.name, newAtt.value )
      }
    }
  }
  

  /**
   * This is called at run-time on the client after the components are all hydrated.
   * One first-page-load, the metadata tags are already in the page html which we get
   * from the server, hooray. But after that, when Vue is mutating the DOM during 
   * navigation, we need to mutate the metadata dom-elements dynamically. Note that this
   * matches what the server renders. At any time if you refresh the page you'll get 
   * the exact same meta-tags in the page html, because the hydration is universal.
   * 
   */
  async mapToDOM() {

    const { metaContext } = this

    const htmlAttrs = metaContext.htmlAttrs || []
    const bodyAttrs = metaContext.bodyAttrs || []
    const headAttrs = metaContext.headAttrs || []
    const appAttrs = metaContext.appAttrs || []
    const metaItems = metaContext.meta || {}
    const externalCSS = metaContext.externalCSS || []
    const extraJS = metaContext.extraJSFiles || null
  
    const head = document.querySelector("head")
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    const app = document.getElementById("app")
    let title = document.querySelector("title")
    let canonical = document.querySelector("link[rel='canonical']")
    const xCSS = document.querySelector("link[data-xcss='1']") || []

    //set the title, create element if it's absent
    if(!title) {
      title = document.createElement("title")
      head.appendChild(title)
    }
    if(metaContext.title) {
      title.innerText = metaContext.title 
    }

    //set the canonical url, create element if it's absent
    if(!canonical) {
      canonical = document.createElement('link')
      canonical.setAttribute("rel","canonical")
      head.appendChild(canonical)
    }
    if(metaContext.canonicalURL) {
      canonical.setAttribute("href", metaContext.canonicalURL)
    }

    this.updateAttributes(html, htmlAttrs)
    this.updateAttributes(head, headAttrs)
    this.updateAttributes(body, bodyAttrs)
    this.updateAttributes(app, appAttrs)
    this.updateMetadata(head, metaItems)
    this.updateExternalCSS(xCSS, head, externalCSS)
    await this.updateExternalJS(extraJS)
  }


  /**
   * @returns the metadata and the duplication-guard
   * 
   */
  getMetaData() {
    return this.metaContext
  }
  getDuplicationJSON() {
    return this.duplicateGuard
  }


  /**
   * @param {*} preloadFiles 
   * Set the preload files and the sorted preload files on the metaContext.
   * Respect all of the sorting buckets.
   * 
   */
  addPreloadFiles(preloadFiles) {
    const currentFiles = this.metaContext.preloadFiles || { images:[], fonts:[], css:[], js:[], all:[] }
    const { images:cImages, fonts:cFonts, css:cCss, js:cJs, all:cAll } = currentFiles
    const { images:nImages, fonts:nFonts, css:nCss, js:nJs, all:nAll} = this._sortPreloads(preloadFiles)

    this.metaContext.preloadFiles = { 
      images:[...cImages, ...nImages], 
      fonts:[...cFonts, ...nFonts], 
      css:[...cCss, ...nCss],
      js:[...cJs, ...nJs],
      all:[...cAll, ...nAll ]
    }
  }

  
  /**
   * @param {*} extraJSFiles 
   * Set the extra-js files on the meta-context. 
   * Respect all of the sorting buckets.
   * 
   */
  addExtraJSFiles(extraJSFiles) {
    const currentFiles = this.metaContext.extraJSFiles || { head:[], body:[], all:[] }
    const { head:cHead, body:cBody, all:cAll } = currentFiles
    const { head:nHead, body:nBody, all:nAll, dedup} = this._sortExtraJS(extraJSFiles)

    this.metaContext.extraJSFiles = { 
      head:[...cHead, ...nHead], 
      body:[...cBody, ...nBody], 
      all:[...cAll, ...nAll ]
    }

    let metaDedup = this.metaContext.extraDedup

    if(!metaDedup) {
      this.metaContext.extraDedup = dedup
    } else {
      const newDedup = {...metaDedup, ...dedup}
      this.metaContext.extraDedup = newDedup
    }

  }


  /**
   * @param {Array (Required)} externalCSS 
   * 
   * Set the external-css on the meta-context.
   * 
   */
  addExternalCSS(externalCSS) {
    const currentExternalCSS = this.metaContext.externalCSS || []
    this.metaContext.externalCSS = [...currentExternalCSS,  ...externalCSS]
  }


  /**
   * @returns string
   * 
   * Output the contents of the head from all of the stored metadata that was entered
   * 
   */
  renderHeadString() {

    const { metaContext } = this

    let output = ''

    output += this._renderHeadStringTitle(metaContext.title)
    output += this._renderCanonicalLink(metaContext.canonicalURL)
    output += this._renderHeadStringMetaTags(metaContext.meta)
    output += this._renderHeadStringPreloadTags(metaContext.preloadFiles)
    output += this._renderHeadStringExtraJS(metaContext.extraJSFiles)
    output += this._renderHeadStringExternalCSS(metaContext.externalCSS)

    return output
  }

  
  /**
   * @returns string
   * 
   * Output the script tags that are supposed to go at the end of the body tag.
   * This isn't the ones from the framework, these are the ones that the user includes in the hydrate options
   * 
   */
  renderEndOfBodyString() {

    const { metaContext } = this

    let output = ''
    if(metaContext.extraJSFiles) {
      const { body } = metaContext.extraJSFiles 

      for(let i=0; i<body.length; i++) {
        const ith = body[i]

        if(ith.render) {
          output += ith.render
        }
        delete ith.render
      }
    }

    return output
  }
  

  /**
   * @returns string 
   * 
   * renders the attributes for the html tag
   *
   */
  renderHTMLAttributes() {
    return this._renderAtts("htmlAttrs")
  }

  /**
   * @returns string 
   * 
   * renders the attributes for the root app tag
   *
   */
   renderAppAttributes() {
    return this._renderAtts("appAttrs")
  }

  /**
   * @returns string 
   * 
   * renders the attributes for the head tag
   *
   */
  renderHeadAttributes() {
    return this._renderAtts("headAttrs")
  }

  /**
   * @returns string
   * 
   * render the attribute for the fav-icon
   */
  renderFavIconAttribute() {
    return `href="${this.metaContext.favIcon}"`
  }

  /**
   * @returns string 
   * 
   * renders the attributes for the body tag
   *
   */
  renderBodyAttributes() {
    return this._renderAtts("bodyAttrs")
  }


  /////////////
  //         //
  //  A P I  //
  //         //
  /////////////

  /**
   * @param {object, array}
   *   
   * Add an object like {key:xyz, value:abc}
   * or add an array of such objects, and they'll be injected as attributes 
   * in the html tag
   * 
   * You can do this in the App.vue as well as any other descendant component and they'll
   * append new data to the internal data instead of overwriting it
   * 
   */
  addHTMLAttribute(obj) {
    this._addAttribute("htmlAttrs", obj)
  }

  /**
   * @param {object, array}
   *   
   * Add an object like {key:xyz, value:abc}
   * or add an array of such objects, and they'll be injected as attributes 
   * in the root app div tag
   * 
   * You can do this in the App.vue as well as any other descendant component and they'll
   * append new data to the internal data instead of overwriting it
   * 
   */
  addAppAttribute(obj) {
    this._addAttribute("appAttrs", obj)
  }


  /**
   * @param {object, array}
   *   
   * Add an object like {key:xyz, value:abc}
   * or add an array of such objects, and they'll be injected as attributes 
   * in the head tag
   * 
   * You can do this in the App.vue as well as any other descendant component and they'll
   * append new data to the internal data instead of overwriting it
   * 
   */
  addHeadAttribute(obj) {
    this._addAttribute("headAttrs", obj)
  }


  /**
   * @param {string} favIcon 
   * 
   * Add the fav-icon. Just the uri to the resource, nothing fancy.
   * 
   */
  addFavIcon(favIcon) {
    this.metaContext.favIcon = favIcon
  }

  
  /**
   * @param {object, array}
   *   
   * Add an object like {key:xyz, value:abc}
   * or add an array of such objects, and they'll be injected as attributes 
   * in the html tag
   * 
   * You can do this in the App.vue as well as any other descendant component and they'll
   * append new data to the internal data instead of overwriting it
   * 
   */
  addBodyAttribute(obj) {
    this._addAttribute("bodyAttrs", obj)
  }


  /**
   * @param {object, array}
   *   
   * Add an object like {name:xyz, content:abc}, {property:xyz, content:abc}, {"html-equiv":xyz, content:abc}, 
   * or add an array of such objects, and they'll be injected as meta tags
   * 
   * You can do this in the App.vue as well as any other descendant component and they'll
   * append new data to the internal data instead of overwriting it
   * 
   */
  addMetaItem = (obj) => {
    const { metaContext, duplicateGuard } = this

    if(!metaContext.meta) {
      metaContext.meta = {}
    }

    let arr = null
    const items = metaContext.meta
    const dedupes = duplicateGuard.meta

    if(obj.constructor === Array) {
      arr = obj
    } else 
    if(obj.constructor === Object) {
      arr = [obj]
    }

    //loop through, resolve duplicates, add the de-duplicated meta-item to the 
    //list of metatags to handle.
    const l = arr.length 
    const { navStack } = this.hydrationContext
    const { currentPriority } = this

    for(let i=0; i<l; i++) {
      const item = arr[i]

      const {key, value} = this.keyValueContent(item, true)
      const dKey = `${key}-${value}`
      const occupant = dedupes[dKey] || 0

      const { priority, navIdx } = occupant

      if(!occupant || currentPriority >= priority || navStack > navIdx) {
        dedupes[dKey] = { priority:currentPriority, navIdx:navStack }
        items[dKey] = item
      }
    }

  }



  /**
   * @param {string}
   *   
   * Add the title 
   * 
   */
  addTitle(titleString) {
    this.metaContext.title = titleString
  }


  /**
   * @param {String (Optional)} canonicalURL. If omitted, this will fall 
   * back to the getFullURL function from the EnviromentHelper
   * 
   * Add the canonical url, so that this kind of tag makes it to the page:
   * <link rel="canonical" href="https://mydomain.com/my/path" />`
   * 
   */
  addCanonical(canonicalURL) {
    this.metaContext.canonicalURL = canonicalURL || this.fullURL
  }

}

export default Meta

export const sunriseProteaRolloverImg = {
  loader: {
    wheel: "dual-ring",
    fromProject: true,
  },
  display:'region',
  region: '90 4 4',


  ldisplay:'fit',
  lfit:'contain',
  lgutter:30,
  lwidth:'100%',
  laspect:1
}


// export const headerImgTheme = {
//   loader: {
//     wheel: "dual-ring",
//     fromProject: true,
//   },

//   display: 'fit',
//   fit:'cover',
//   // display:'region',
//   // region:'90 4 4',
//   width:'33.33%',
//   height: '130%',
//   //height: 'calc(100% + 30px)',

//   ldisplay:'fit',
//   lfit:'contain',
//   lgutter:30,
//   lwidth:'100%',
//   //// laspect:1

// }


export const headerImgTheme = {
  loader: {
    wheel: "dual-ring",
    fromProject: true,
  },

  display: 'fit',
  fit:'cover',
  height:"130%",
  width:"110%",
  // display:'region',
  // region:'90 4 4',

  ldisplay:'fit',
  lfit:'contain',
  lgutter:30,
  lwidth:'100%',
  //// laspect:1

}

// export const headerImgTheme = {
//   loader: {
//     wheel: "dual-ring",
//     fromProject: true,
//   },

//   // display: 'fit',
//   // fit:'cover',
//   display:'region',
//   region:'100 4 4',
//   width:'33.33%',
//   height: 'calc(100% + 30px)',

//   ldisplay:'fit',
//   lfit:'contain',
//   lgutter:30,
//   lwidth:'100%',
//   //// laspect:1

// }


export const xLazyTheme = {
  loader: {
    wheel: "dual-ring",
    fromProject: true,
  },

  display:'region',
  region: '90 4 4',
  //width:'100%',

  //Works. img
  // display: 'auto',
  // width:'100%',

  //Works
  // display: 'fit',
  // fit:'contain',
  // position:'top',
  // width:'100%',
  // aspect:1,

  //Works
  // display:'region',
  // region: '70 0 0',
  // width:'100%',
  // aspect:1.5,
  // tag: 'div',

  // ldisplay:'fit',
  // laspect:1,
  // lgutter:20

  ldisplay:'fit',
  lfit:'contain',
  lgutter:30,
  lwidth:'100%',
  laspect:1

  // ldisplay:'fit',
  // lwidth:'20%',
  // lfit:'contain'

}

export const availabilityImgTheme = {
  ...xLazyTheme,
  ldisplay:'fit',
  lfit:'contain',
  lwidth:'100px',
  lgutter:null,
  laspect:1
}
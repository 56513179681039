import { post, API_LOC_WPJWT_NS } from '@kit/utils/APIV1'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { store } from '@/store'
import { AUTH_LOGIN_STATE_COLD, AUTH_LOGIN_STATE_FALSE, AUTH_LOGIN_STATE_TRUE } from '@kit/utils/auth/Auth'

export const WP_JWT_LOGIN_RECAPTCHA_ACTION = 'wp_jwt_login'
export const JWT_LOCALSTORE_NAME = 'jwt_85734732d'
export const JWT_USERINFO_LOCALSTORE_NAME = '7573hgdct23'




/**
 * Logs us into the wordpress by providing a jwt.
 * /cms-service/index.php/wp-json/v1/wp-jwt-login
 *  
 * @param {String} username. Required. The username
 * @param {String} password. Required. The password.
 * @param {String} token. The recaptcha token. When generating the recaptcha token
 *   on the client, make sure you use the above WP_JWT_LOGIN_RECAPTCHA_ACTION.
 * @returns {Object} The response from the server
 * 
 * 
 */
export const wpJWTLogin = async(username, password, token, getUserInfo) => {
  const jsonFormData = { username, password, token }
  jsonFormData["action"] = WP_JWT_LOGIN_RECAPTCHA_ACTION
  const response = await post(`wp-jwt-login`, jsonFormData)

  //Will return this if success
  // { 
  //   "token":"eyJgAItsReallyLong2-93yM",
  //   "user_email":"alex@ics.tech",
  //   "user_nicename":"the_admin_name",
  //   "user_display_name":"Alex Lowe"
  // }


  // {
  //   "message":"You're not reading as human",
  //   "code":"not-human",
  //   "status":500,
  //   "error":true,
  //   "success":false
  // }
  if(response["token"]) {
    response["success"] = true

    if(inBrowser) {
      const userInfo = getUserInfo(response) || {}
      window.localStorage.setItem(JWT_LOCALSTORE_NAME, response["token"])
      window.localStorage.setItem(JWT_USERINFO_LOCALSTORE_NAME, JSON.stringify(userInfo))
      store.dispatch("wpJWTLoggedIn", userInfo)
    }

  } else {
    response["success"] = false
  }

  return response
}

/**
 * Logout by erasing the jwt.
 * 
 */
export const wpJWTLogout = () => {
  if(inBrowser) {
    window.localStorage.removeItem(JWT_LOCALSTORE_NAME)
    window.localStorage.removeItem(JWT_USERINFO_LOCALSTORE_NAME)
    store.dispatch("wpJWTLoggedOut")
  }
}


/**
 * @returns {String} the token or null.
 * 
 */
export const wpJWTGetToken = () => {
  if(inBrowser) {
    return window.localStorage.getItem(JWT_LOCALSTORE_NAME) || null
  }
  return null
}

export const wpJWTGetUserInfo = () => {
  if(inBrowser) {
    try {
      const userInfo = JSON.parse(window.localStorage.getItem(JWT_USERINFO_LOCALSTORE_NAME)) || null
      return userInfo
    } catch(e) {
      return null
    }
  }
  return null
}



/**
 * @returns {Boolean} true if logged in with a valid token, false if otherwise.
 * 
 * As a side effect, this will handle the local-storage and the vuex store.
 * 
 * The token/validate endpoing
 * {
 *   code: 'jwt_auth_invalid_token', 
 *   message: 'Expired token', 
 *   data: {
 *     status:403  
 *   }
 * }
 */
export const wpJWTIsLoggedIn = async() => {
  const jwt = wpJWTGetToken()
  if(!jwt) {
    wpJWTLogout()
    return false
  } else {
    const response = await post('token/validate', {}, API_LOC_WPJWT_NS)
    const isValid = response.data.status == 200

    //If not valid, then log us out
    if(!isValid) {
      wpJWTLogout()
    } 
    
    //if it is valud, then we're going to see if this is a COLD test,
    //which is to say, it hasn't been run yet. But, the token is valid, so
    //if stands to reason that the userInfo must still be there. We're going
    //to dispatch the wpJWTLoggedIn action to refresh everything.
    else {

      if(store.state.loggedIn == AUTH_LOGIN_STATE_COLD) {
        const userInfo = wpJWTGetUserInfo()
        store.dispatch("wpJWTLoggedIn", userInfo)
      }

    }
    return isValid
  }
}




//https://localhost/cms-service/index.php/wp-json/v1/logout
// export const wplogout = async() => {
//   return get(`wp-logout`)
// }

/*
----------------------------------------------------------------------------
--
-- Resize
-- 
-- A basic class to handle resize debouncing events across different components.
-- I have all of these UI elements that have to respond to debounced resize events,
-- particularly anything with intersection-observers. This provides a way to add 
-- resizing behavior to all of those things.
--
-- Author Alex Lowe
--
----------------------------------------------------------------------------

resizable-object must conform to this interface:
  
  setResizer(resizer) {
    this.resizer = resizer
  }
  
  resized(windowInnerWidth) {
    //do stuff here.
  }

  upon cleanup, the resizableObject must call:

  this.resizer.destroy()
  this.resizer = null

  in the constructor of the resizeable object, register it this way:

  class MyResizableObject {

    constuctor() {
      this.resizer = null
      Resize.register(this)
    }

*/

const wCtx = typeof window != 'undefined' ? window : null

class BasicResizer {
  constructor(callback) {
    this.callback = callback
  }

  setResizer(resizer) {
    this.resizer = resizer
  }

  resized(windowInnerWidth) {
    this.callback(windowInnerWidth)
  }

  refresh() {
    this.resizer.refresh()
  }

  destroy() {
    if(this.resizer) {
      this.resizer.destroy()
      this.resizer = null
    }
  }

} 


class Resize {

  static inBrowser = typeof window !== 'undefined'
  static listeningForResizes = false
  static firstInstance = null
  static lastInstance = null
  static numInstances = 0
  static dInterval = null
  static dCache = null

  constructor(resizableObject) {
    this.resizableObject = resizableObject
    this.prev = null
    this.next = null
    this.destroyed = false
    this.addToChain()
    resizableObject.setResizer(this)
    Resize.listenForResize()
  }

  addToChain() {
    if(Resize.numInstances == 0) {
      Resize.firstInstance = this
      Resize.lastInstance = this
    } else {

      if(Resize.lastInstance) {
        Resize.lastInstance.next = this
      }
      this.prev = Resize.lastInstance
      Resize.lastInstance = this
    }
    Resize.numInstances++
  }
  removeFromChain() {
    if(this.prev) {
      this.prev.next = this.next
    } else {
      Resize.firstInstance = this.next
    }
    if(this.next) {
      this.next.prev = this.prev
    } else {
      Resize.lastInstance = this.prev
    }
    Resize.numInstances--

    if(!Resize.firstInstance && !Resize.lastInstance) {
      Resize.unlistenForResize()
    }
  }


  static debounceInstances(value) {
    let resizer = Resize.firstInstance

    while(resizer) {
      resizer.resizableObject.resized(value)
      resizer = resizer.next
    }
  }

  static debouncer(value) {
    if(!Resize.dInterval) {
      Resize.debounceInstances(value)

      Resize.dInterval = setInterval(() => {
        if(Resize.dCache !== null) {
          Resize.debounceInstances(Resize.dCache)
          Resize.dCache = null
        } else {
          clearInterval(Resize.dInterval)
          Resize.dInterval = null
        }
      },500)
      
    } else {
      Resize.dCache = value
    }
  }

  //A function to force a refresh
  refresh() {
    Resize.screenResized()
  }

  static screenResized() {
    Resize.debouncer(window.innerWidth)
  }

  static listenForResize() {
    if(Resize.inBrowser && !Resize.listeningForResizes) {
      Resize.listeningForResizes = true
      if(wCtx) {
        wCtx.addEventListener('resize', Resize.screenResized, { passive:true })
      }
      Resize.screenResized()
    }
  }

  static unlistenForResize() {
    if(Resize.inBrowser && Resize.listeningForResizes) {
      Resize.listeningForResizes = false
      if(wCtx) {
        wCtx.removeEventListener('resize', Resize.screenResized, { passive:true })
      }
    }
  }

/////////////
//         //
//  A P I  //
//         //
/////////////

  /**
   * @param {Function, required} resizableFunction
   * 
   *   Short version: HAS a resizer
   * 
   *   Pass in a callback, which will receive the window-inner-width. 
   *   What will happen is that this function will create a BasicResizer 
   *   object which will obey the resizeable-object interface. When you're done 
   *   with the resizer, it will be up to you to destroy it.
   * 
   * 
   * @param {Object, required} resizableObject 
   * 
   *    Short version: IS a resizer
   *
   *    Pass in a reference to your resizable-object.
   *    The resizable-object must conform to this interface:
   *      
   *      setResizer(resizer) {
   *        this.resizer = resizer
   *      }
   *      
   *      resized(windowInnerWidth) {
   *        //do stuff here.
   *      }
   *
   *      upon cleanup, the resizableObject must call:
   *
   *      this.resizer.destroy()
   *      this.resizer = null
   *
   * 
   */
  static register(resizableObjectOfFunction) {

    const resizableObject = typeof resizableObjectOfFunction == "function" ? new BasicResizer(resizableObjectOfFunction) : resizableObjectOfFunction

    if(this.destroyed) {
      return
    }
    const r = new Resize(resizableObject)
    resizableObject.setResizer(r)

    return resizableObject
  }


  /**
   * Called upon cleanup by the resizable object.
   * 
   */
  destroy() {

    if(this.destroyed) {
      return
    }
    this.destroyed = true
    
    if (Resize.inBrowser) {

      this.removeFromChain()

      this.scrollTriggers = []
      this.scrollTriggers = null
      this.prev = null 
      this.next = null

    }
    
  }

}

export default Resize

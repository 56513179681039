
import { pathname } from '@kit/utils/Formats'
import "@images/SunriseProteaLogo.png"

export const navbar = {

  //optional. if it will shrink on scroll
  scrollShrink: true, //false,

  outerContainerClassesDesktop:"ics-nav-outer ics-navbar-boxshadow--attop",
  //outerContainerclassesDesktopScrolled: "ics-nav-outer ics-navbar-boxshadow",

  outerContainerClassesMobile:"ics-nav-outer ics-navbar-boxshadow--attop",
  //outerContainerClassesMobileScrolled: "ics-nav-outer ics-navbar-boxshadow",

  //shrinkColor:"rgba(255,255,255,1)",
  backgroundColor:"linear-gradient(135deg, rgba(255,254,254,1) 0%, rgba(241,175,126,0.8) 57%, rgba(246,202,238,0.9) 100%);",
  initialColor:"rgba(255,255,255,1)",
  animateBackgroundChange: false,
  backgroundProperty:'background-color',

  hamburgerButtonClasses:"ics-nav-hamburger-nav-button--attop",

  //the logo and aria text
  logoWrapperClasses:"ics-logo-wrapper",
  logoSrc:pathname("@images/SunriseProteaLogo.png"),
  logoAriaAlt:"Sunrise Protea Ranch navigation logo",

  //required
  height: "90px",
  shrinkHeight: "50px",

  padding: "15px",
  shrinkPadding: "5px",

  //optional. The top gap
  topGap: "0px"
    
}

export const sideDrawer = {
  topNavHeight:"50px",
  outerClasses:"ics-side-nav-drawer"
}
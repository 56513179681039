<script>
import { defineComponent, computed, inject } from "vue";
import SidebarRouteLink from "@kit/components/SidebarRouteLink.vue";
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import SideNavDrawerBase from "@kit/components/SideNavDrawerBase.vue";
import { searchWidgetMobile, sideDrawer  } from "@project/themes";
import IconButton from '@kit/components/IconButton.vue'
import { getCurrentYear } from '@kit/utils/Formats'

export default defineComponent({
  name: "SizeNavDrawer",
  setup() {

    const hydrate = inject('hydrate')

    const { prefetch } = hydrate({
      prefetch:['families','varieties']
    })

    return { searchWidgetMobile, sideDrawer, prefetch, getCurrentYear };

  },
  components: {
    SideNavDrawerBase,
    SidebarRouteLink,
    NavMainSearch,
    IconButton
  },
});
</script>

<style>

.ics-side-nav-drawer {
  background: rgb(215,82,51);
  background: linear-gradient(90deg, rgba(215,82,51,1) 0%, rgba(246,114,98,1) 49%, rgba(201,86,131,1) 100%);
}


.ics-sidenav-item {
  color:white;
  font-size:1.5rem !important;
}
.ics-sidenav-copyright {
  color:white;
  font-size:1.2rem;
}

</style>

<template>
  <SideNavDrawerBase :theme="sideDrawer">
    <template #nav-items="{ active }">


      <div class="sb">
        <SidebarRouteLink class="ics-sidenav-item" to="/">Home</SidebarRouteLink>
      </div>

      <div class="sb sb-v">
        <span class="sb ics-sidenav-item">Families</span> 
        <div v-if="prefetch && prefetch.families">
          <template v-for="family in prefetch.families">
            <SidebarRouteLink class="sb ics-sidenav-item" style="margin-left:20px;" :to="`/family/${family.url_slug}`">{{family.display_title}}</SidebarRouteLink>
          </template>
        </div>
      </div>

      <div class="sb sb-v">
        <span class="sb ics-sidenav-item">Varieties</span> 
        <div v-if="prefetch && prefetch.varieties">
          <template v-for="variety in prefetch.varieties">
            <SidebarRouteLink class="sb ics-sidenav-item" style="margin-left:20px;" :to="`/variety/${variety.url_slug}`">{{variety.display_title}}</SidebarRouteLink>
          </template>
        </div>
      </div>

      <!-- <div class="sb">
        <SidebarRouteLink class="ics-sidenav-item" to="/about">About Us</SidebarRouteLink>
      </div> -->

      <div class="sb">
        <SidebarRouteLink class="ics-sidenav-item" to="/contact-us">Contact Us</SidebarRouteLink>
      </div>

      <!-- <NavMainSearch :theme="searchWidgetMobile" :active="active" />
       -->
       
      <div class="sb sb-text ics-sidenav-copyright">
        © {{ getCurrentYear() }} Sunrise Protea. All rights reserved
      </div>

    </template>
  </SideNavDrawerBase>
</template>

/////////////////////////////////////////////////////////////
//
//  A helper utility to get a configuration from a 
//  set of configurations and return a default setting if 
//  no other setting is found
//
/////////////////////////////////////////////////////////////

class Config {
  
  constructor(settingsObj, defaultObj) {
    this._isConfig = true
    this._defaultObj = defaultObj || null
    this._settingsObj = settingsObj
    this._cache = {}
  }

  destroy() {
    this._defaultObj = null 
    this._settingsObj = null 
    this._cache = null
  }


  resetSetting(aSettingPath, value) {
    this._cache[aSettingPath] = value 
  }

  resetConfig(newConfigObj) {
    this._cache = null
    this._settingsObj = newConfigObj
    this._cache = {}
  }


  getSetting(aSettingPath) {
    if(!this._settingsObj) {
      throw new Error("No settings have been set.")
    }

    const setting = this._cache[aSettingPath]

    //if the one we retrieved is not undefined, then return it so we don't
    //have to do all this rigamarole. Settings objects never change
    if(setting !== undefined) {
      return setting
    } else {

      const settingsObject = this._settingsObj
      const split = aSettingPath.split(".")

      let currentSettingLayer = settingsObject
      let currentDefaultLayer = this._defaultObj

      for(let i=0; i<split.length; i++) {
        const key = split[i]
        let newSLayer = undefined
        let newDLayer = undefined

        if(currentSettingLayer) {
          newSLayer = currentSettingLayer[key]
        }
        if(currentDefaultLayer) {
          newDLayer = currentDefaultLayer[key]
        }

        currentSettingLayer = newSLayer 
        currentDefaultLayer = newDLayer        
      }

      if(currentSettingLayer === undefined) {
        currentSettingLayer = currentDefaultLayer
      }

      const newValue = currentSettingLayer
      
      this._cache[aSettingPath] = newValue 
      return newValue 

    }

  }

}

export default Config
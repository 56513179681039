
<script>
import { defineComponent, computed, onMounted, inject } from 'vue'
import { footerButton } from '@project/themes'
import IconButton from '@kit/components/IconButton.vue'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { getCurrentYear } from '@kit/utils/Formats'
import ParallaxImage from '@kit/components/ParallaxImage.vue'
import Column from '@kit/components/Column.vue'
import "@images/AmericanGrown_1.jpg" 
import "@images/CAGrown-1.jpg" 
import "@images/SunriseProteaLogo-LightVersion.png" 

export default defineComponent({
  name: "Footer",
  setup() {


    const hydrate = inject('hydrate')

    const { prefetch } = hydrate({
      prefetch:['home']
    })

    const addressAndPhone = computed(() => {
      if(prefetch.value && prefetch.value.home) {
        const address = prefetch.value.home.footer_address 
        const phone = prefetch.value.home.footer_phone_number
        return `${address}${ phone ? '<br>Phone: '+phone : '' }`
      }
      return ""
    })

    //const store = useStore()
    //const locations = computed(() => store.state.locations)
    const locations = computed(() => [])

    //"home"

    onMounted(() => {})

    return { 
      locations, 
      footerButton, 
      inBrowser,
      getCurrentYear,
      prefetch,
      addressAndPhone
    }

  },
  components: {
    IconButton,
    ParallaxImage,
    Column
  }
});
</script>

<style>
  .footer-button svg.sb-abox {
    transform:translate(-10px, -3px) !important; 
  }
</style>

<style scoped>

  .title-img-width {
    width: 50% !important;
  }
  @media (max-width : 960px) {

    .title-img-width {
      width: 70% !important;
    }

  }
  @media (min-width : 1200px) {
    .title-img-width {
      width: 500px !important;
    }
  }

  .address {
    line-height: 1.5;
    font-size: 1.2rem;
  }

  .locations-outer {
    /* padding-left:80px; padding-right:80px; */
  }
  .locations-inner {
    padding:50px; 
    padding-bottom:20px; 
    color:white; 
    /* background:linear-gradient(-50deg, rgb(73, 151, 98) 0%, rgb(49, 93, 51) 100%); */
    /* background:linear-gradient(114deg, rgba(213,104,165,1) 0%, rgba(191,51,20,1) 100%); */
    /* background: linear-gradient(114deg, rgba(213,138,104,1) 0%, rgba(221,187,118,1) 100%); */
    /* background: linear-gradient(114deg, rgba(213,127,104,1) 0%, rgba(230,160,190,1) 100%); */
    /* background: linear-gradient(75deg, rgba(77,113,68,1) 0%, rgba(160,230,207,1) 100%); */
    /* background: linear-gradient(114deg, rgba(213,127,104,1) 0%, rgb(131, 56, 62) 100%); */
    background: linear-gradient(114deg, rgba(213,127,104,1) 0%, rgb(131, 56, 62) 100%);
  }
  .parallax-cover {
    /* background:linear-gradient(90deg, rgba(96,198,159,0.4) 0%, rgba(45,79,58,0.8) 70%);  */
    /* background:linear-gradient(114deg, rgba(113, 21, 87, 0.4)0%, rgba(148, 38, 13, 0.8) 100%); */
    background:linear-gradient(114deg, rgba(128, 2, 92, 0.4)0%, rgba(88, 18, 2, 0.8) 100%);
  }



  @media (max-width : 960px) {

    .locations-outer {
      padding-left:0px;
      padding-right:0px;
    }

  }






</style>

<template>

  <Column tag="footer" class="sb-v" gtH="1200" ltD="100" ltT="100" ltP="100" style="margin-top:50px;">

    <div class="sb sb-h sb-align-ch" style="padding-top:20px; padding-bottom:20px;">
      <ParallaxImage :theme='{ parallax:{ background: { width:850, height:850 }}}' ariaImgLabel="background image for footer" src="/cms-static-assets/2024/04/FooterBackground.png"/>
      <div class="sb sb-spread parallax-cover"></div>

      <div class="sb sb-v sb-explicit title-img-width">
        <img class="sb" style="width:100%; height:auto; object-fit:contain;" alt="Sunrise Protea Ranch ?" src="@images/SunriseProteaLogo-LightVersion.png">
      </div>
    </div>


    <div class="sb sb-v locations-outer" v-if="prefetch && prefetch.home">
      <div class="sb sb-v sb-alt2 sb-align-ch sb-g20 locations-inner">
      
        <div class="sb sb-h sb-ltm-h2v sb-ltm-alt2 sb-align-ch sb-align-2bottom">
          <img alt="American Grown Logo" class="sb sb-explicit" style="width:100px; height:auto; object-fit:contain;" src="@images/AmericanGrown_1.jpg">
          <div class="sb sb-text address" v-html="addressAndPhone"></div>
          <img alt="California Grown Logo" class="sb sb-explicit" style="width:100px; height:auto; object-fit:contain;" src="@images/CAGrown-1.jpg">
        </div>
        <div class="sb sb-h sb-align-ch sb-ltm-alt2 sb-ltm-h2v">
          <router-link class="sb sb-text" style="color:white;" to="/contact-us">Contact Us</router-link>
          <a class="sb sb-text" :href="`mailto:${prefetch.home.footer_email_address}`" style="color:white;">{{ prefetch.home.footer_email_address }}</a>
        </div>
        <div class="sb sb-v" style="width:100%;">
          <div class="sb sb-explicit sb-h sb-align-2right">
            <b class="sb sb-text source-serif">©  {{getCurrentYear()}} Sunrise Protea Ranch</b>
          </div>
        </div>

      </div>
    </div>
  </Column>

</template>
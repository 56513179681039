
<script>
import { defineComponent, onMounted, ref, onBeforeUnmount, computed, inject } from 'vue'
import Scroller from '@kit/utils/Scroller'
import { animate } from '@kit/utils/Animate'
import Config from '@kit/utils/Config'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { sleep } from '@kit/utils/Sleep'

/**
  loader: object, optional. 
  {
    wheel: optional. default is "Spinner-04s-200px"
      This is a resource in the assets/images/spinners folder in your project or the base-project
    
    fromProject: optional. default is false
  } 

  style: optional. default is ""

  class: optional. default is ""
  
  loadingStyle: optional. default is empty. the mergeable style for the loading state
  
  loadingClass: optional. default is empty. The mergeable classes for the loading state

  background: optional. default is false If true, then this will be a div with a background-image.
 **/

/**
  Simple example, using background.

    <LazyImg 
      :src="'https://ics.devapps-dlnkeicmwjs0192.com/cms-service/wp-content/uploads/2023/02/NCook-Bio-Pic-resized-cropped.png'" 
      :style="'min-height:400px;'" 
      :background="true">
      Testing testing 1 2 3
    </LazyImg>



 **/

const defaultImgConfig = new Config({
  loader: {
    wheel: "Spinner-04s-200px",
    fromProject: false,
  },
  style: "",
  class: "",
  loadingStyle: "object-fit:contain; width:100%; height:auto; padding: 20px",
  loadingClass: "",
  background: false,
})

const defaultDivConfig = new Config({
  loader: {
    wheel: "Spinner-04s-200px",
    fromProject: false,
  },
  tag:"div",
  style: "background-size:cover; background-repeat:no-repeat; background-position:center;",
  class: "",
  loadingStyle: "background-size:150px 150px; background-repeat:no-repeat; background-position:center;",
  loadingClass: "",
  background: true,
})

//specifying the background-size and object-fit here is stupid.
//What do we want here?

//We want:
//Image goes full-width, height:auto
//Image goes width:auto, full-height

//Image is active, meaning that it's going to take up space on its own
//Image is passive, meaning that it's going to react to the space that its given

//Image is going to be background or foreground
//If foreground

//If the image has a clip area, that means that its going to live inside a div 


export default defineComponent({
  name: "LazyImg",
  props: {

    "src": { required: true },

    //The theme
    "theme": { required: false },

    "tag": { required: false, default:"div" },

    //The theme properties that can be overridden.
    "style": { required: false },
    "class": { required: false },

    //loadingStyle and loadingClass will both default to style and class if they're not included.
    "loadingStyle": { required: false, default: (props) => props.style },
    "loadingClass": { required: false, default: (props) => props.class },

    //the background theme property
    "background": { required: false },

    //the aria label
    "ariaLabel": { required: false },

    //the position. background-position or object-position depending on the value if background.
    "position": { required: false }
  },

  setup(props, context) {

    let scroller = null
    
    const root = ref(null) 
    const loaded = ref(false) 

    let loaderURL = ""

    const themer = inject("themer")

    const defaultConfig = props.background ? defaultDivConfig : defaultImgConfig

    const { getProp, setProp } = themer({props, defaultConfig })

    const loadImg = async() => {
      await new Promise((resolve, reject) => {
        let img_ = new Image()
        img_.onload = () => {
          resolve()
        }
        img_.onerror = () => {
          reject()
        }
        img_.src = props.src
     
      })
    }

    const enteredIntoView = async(info) => {
      if(!loaded.value && inBrowser) {
        await loadImg()
        const el = info.element
        loaded.value = true  
        context.emit("lazyImgLoaded")
        el.style.opacity = 0;
        animate({targets:el, opacity:1, easing:"linear", duration:300 })
      }
    }

    const loaderSrc_ = computed(() => {
      const gif = getProp("loader.wheel")
      if(getProp('loader.fromProject')) {
        require(`@project/assets/images/spinners/${gif}.gif`);
      } else {
        require(`@kit/assets/images/spinners/${gif}.gif`);
      }
      loaderURL = `/assets/${gif}.gif`
      return loaderURL
    })


    const imgSrc_ = computed(() => {
      if(loaded.value ) {
        return props.src
      } else {
        return loaderSrc_.value
      }
    })

    const style_ = computed(() => {
      if(!getProp("background")) {
        if(loaded.value) {

          const pos = props.position ? ` object-position:${props.position};`: ''
          return `${getProp("style", "style")}${pos}`

        } else {
          return getProp("loadingStyle", "style")
        }
      } else {

        if(loaded.value) {

          const pos = props.position ? ` background-position:${props.position};`: ''
          return `background-image:url('${imgSrc_.value}'); ${getProp("style", "style")}${pos}`

        } else {
          return `background-image:url('${imgSrc_.value}'); ${getProp("loadingStyle", "style")}`
        } 
      }
    })

    const class_ = computed(() => {
      if(loaded.value) {
        return getProp("class")
      } else {
        return getProp("loadingClass")
      }
    })

    onMounted(() => {
      if(!scroller) {
        let scroller = new Scroller()
        //scroller.register(root.value, {"type":"asyncAction", "debugStyle":"background:#FF0055; opacity:0.5;", "th":0.1, "dy":-100, "action":enteredIntoView})
        scroller.register( root.value.$el || root.value, {"type":"asyncAction", "th":0.1, "dy":-100, "action":enteredIntoView})
      
      }
    })
    onBeforeUnmount(() => {
      if(scroller) {
        scroller.destroy()
        scroller = null
      }
    })

    
    return { root, imgSrc_, style_, class_, getProp, loaded, enteredIntoView }
  },
  emits: ["lazyImgLoaded"]
});
</script>

<style scoped>
  img {
    box-sizing: border-box;
  }
</style>

<template>
  <img v-if="!getProp('background')" :alt="getProp('ariaLabel')" :src="imgSrc_" :style="style_" :class="class_" ref="root">
  <component v-else :is="getProp('tag')" aria-role="img" :style="style_" :class="class_" ref="root" :aria-label="getProp('ariaLabel')"><slot></slot></component>
</template>
import { wpJWTIsLoggedIn } from "@kit/utils/auth/wp-jwt/WPJWT"
import { inBrowser, getEnv } from "@kit/utils/EnvironmentHelper"

export const AUTH_TYPE_WPJWT = 'wp-jwt'

export const AUTH_TYPE_MSAL = 'msal'

export const AUTH_TYPES = [AUTH_TYPE_WPJWT, AUTH_TYPE_MSAL] 

export const AUTH_REDIRECT_AFTER_LOGIN = 'p50x3encbs62f'

export const AUTH_LOGIN_STATE_COLD = -1 

export const AUTH_LOGIN_STATE_FALSE = 0 

export const AUTH_LOGIN_STATE_TRUE = 1

export const isLoggedIn = async() => {
  const whichType = getEnv("AUTH_TYPE")
  if(whichType == AUTH_TYPE_WPJWT) {
    return wpJWTIsLoggedIn()
  }
  return false
}

export const getRedirectAfterLogin = () => {
  if(inBrowser) {
    return window.localStorage.getItem(AUTH_REDIRECT_AFTER_LOGIN) || null
  }
}
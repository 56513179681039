import Config from '@kit/utils/Config'
import { ref } from 'vue'
import { endChar } from '@kit/utils/Formats'
import { nextTick } from 'vue'

/*
  Themer utility.

  The usage is a little complex, but the idea is that it lets us set up
  a complex set of objects that define the theme and behavior of complex components
  so that they can be easily reskinned and reused. We do some acrobatics with 
  reactive refs here so that we can reactively reskin objects if we have to

  ### To pass a theme to a themable component:

  import { myModalTheme } from @project/themes

  setup() {
    const themer = inject("themer")

    const _myModalTheme = themer(myModalTheme)

    //...do your other stuff

    return { _myModalTheme, ...otherstuff }
  }

  <template>
    <MyModal :theme="myModalTheme"/>
  </template>

  #### Then in MyModal.vue:

  --Make the default config object outside the vue component
  
  const defaultConfig = new Config({
    "themeProp1":{ "color":"blue" },
    "themeProp2":42
  })
  
  export default defineComponent({
    name: "MyModal",
    
    --The props can have an optional theme and optional theme properties.
    There's a chain or precedence a given property will look first to the props.
    Then it will look to the theme that you've passed in, and finally it will look to the
    default-configuration.

    props:{
      //the theme. Optional. 
      "theme":{ required: false }

      //exposed theme properties
      "themeProp1": { required: false }
      "themeProp2": { required: false } 
    },

    -- Get the all-important getProp and setProp functions from the themer injection.
    You can use setProp to reskin parts of the theme.
    setup() {

      const themer = inject("themer")
      const { getProp, setProp } = themer({ props, defaultConfig })
    
      //...do other stuff here

      retrun { getProp, ...otherstuff }
    }

  -- In the template, getProp is used to fetch the properties:
  
  <some-component color="getProp('themeProp1.color')"/>

  If you want to fetch a subtheme this way, you have to use

  getProp('path.to.subtheme','theme')


  And then you can use setProp() the same way. If you follow this pattern, then reactivity is maintained
  and the component will change reactively.

  NOTE. If you want to 'force' a change using setProp, pass the true in the force parameter.
  setProp("imageCoverCSS", `background-color:rgba(0,0,255,0.4)`, false, true)

  Background:

  Here's the deal. Flexible components with many different kinds of modes and 
  behaviors are hard. You end up with like 100 properties to specify in the markup.
  Well, that's just kind of nuts.

  It will occur to you to just wrap everything into some kind of theme object,
  that way you can just pass in a single object which will have all those options 
  baked in, and you can the maintain a set of files with theme objects. ES6 makes 
  this easier to do with the spread operator. Not a bad plan. That's how this started.

  The plan gets tricker when you have to reskin the theme or parts of it. What to do?
  Could do something where the entire theme is reactive. Problem there is performance.
  Deep reactivity on these theme objects isn't practical. 

  Anther idea is shallow-refs. Make the theme sort-of reactive at least on a shallow 
  level with the shallowRefs function. I've tried that and it actually works ok. But
  not ok enough. You can tell yourself that your theme will always be shallow, but 
  it just won't.

  Ok, but there's another prerogative here, which is a chain-of-precedence between the 
  theme object, whatever props overrides you might have on your component, and a 
  default theme object. For some property "x": props.x || theme.x || defaultTheme.x
  
  Now, the trick is, making this kind of chain-of-precedence work with reactivity.
  Well, that's why we have this wrapper class ThemeWrapper, and each instance contains
  a reactive ref() object. Then the component gets a getProp and setProp function. getProp
  is reactive because it consumes the ref. setProp updates the config object and the ref.

  See right at the bottom: the themer function. That it wired into the app so you can 
  access it in your components with inject("themer")

  Couple of bugaboos:
  1. We can't do "deep" reskinning.
  See, I have to do this:

        navBarBase.value.reskin([
          { key:"logoSrc", value:pathname("@images/ics-logo-FINAL.png") },
          { key:"hamburgerButtonClasses", value:"ics-nav-hamburger-nav-button" },
          { key:"outerContainerClassesDesktop", value:"ics-navbar-boxshadow" },
          { key:"outerContainerClassesMobile", value:"ics-navbar-boxshadow" },
        ])
 
        //Where we have a special exposed function to reskin an interior object
        //which consumes a button sub-theme.
        navMainSearch.value.reskinSearchButton([
          { key:'foreground', value:"#555555" },
          { key:'iconDimension', value:{
              style: ButtonIconActiveHeight,
              active: '20px'
            },
          }
        ])

  What I'd really LIKE to do is this:

          navBarBase.value.reskin([
          { key:"logoSrc", value:pathname("@images/ics-logo-FINAL.png") },
          { key:"hamburgerButtonClasses", value:"ics-nav-hamburger-nav-button" },
          { key:"outerContainerClassesDesktop", value:"ics-navbar-boxshadow" },
          { key:"outerContainerClassesMobile", value:"ics-navbar-boxshadow" },
          { key:"button.foreground", value:"#555555"},
          { key:"button.iconDimension", value:{
              style: ButtonIconActiveHeight,
              active: '20px'
            },
          } 
        ])

  In order to make that work, there'd have to be some kind of a layer where setProp
  looks at your path "my.theme.heres.button.foreground" and then it determines that 
  you're setting a property 'foreground' on your button subtheme because it knows that 
  there's a subtheme sitting at "my.theme.heres.button". That's for another day.
  


  2. You should probably not do this in an animation. It's for atomic events like a scroll-top
  or a button press where the user wants to switch to night theme or something.

  
*/

export const MERGE_CLASS = 'class'
export const MERGE_STYLE = 'style'
export const STYLE_STRING = 'string'
export const THEME_TYPE = 'theme'
const mergeOrTypeOptionTypes = { 'class':true, 'style':true, 'theme':true, 'string':true }


class ThemeWrapper {

  constructor(themeObj) {
    this._isWrapper = true
    this._ref = ref(1)

    if(themeObj._isConfig) {
      this._obj = themeObj._config
      this._config = themeObj
    } else {
      this._obj = themeObj
      this._config = new Config(themeObj)
    }

    this._nestedThemeKVP = {}
  }
  

  //reset the config with a new object and tick up the ref.
  resetConfig(newObj) {
    this._config.resetConfig(newObj)
    this._ref.value++
  }
  
  //Set a property. If the new property is a theme object,
  //then you have to set the isTheme to true.
  //This is so that it can get turned into a wrapper, or update
  //the current wrapper and tick up the ref contained therein
  //
  //This is so that in some component where you're passing the theme to a
  //themable child component:
  //
  //  setProps() {
  //
  //    const themer = inject("themer")
  //    const themeForChild = themer(aThemeObj)
  //
  //    return { themeForChild }
  //
  //  }
  //
  // In this case you can call themeForChild.setProp() to set a property
  // on that theme. This is a handy thing to be able to do from the parent 
  // component.
  // 
  setProp(property, newVal, isTheme) {
    if(newVal && newVal._isWrapper) {
      throw new Error("Error: setProp isn't meant to be used this way. You don't pass in ThemeWrapper objects")
    }

    //If we're fetching a theme property, then 
    //then we're going to check to see if it's been converted to a 
    //wrapper yet. If it hasn't, then we can just reset the property 
    //like normal. But if it has been converted, then we're going to 
    //reset it. We're going to assume that the newVal is a new theme 
    //object.
    const nT = this._config.getSetting(property)
    
    if(nT && (nT._isWrapper || isTheme)) {
      nT.resetConfig(newVal)
    } else 
    if((!nT || !nT._isWrapper) && isTheme) {
      const wrapper = new ThemeWrapper(newVal)
      this._config.resetSetting(property, wrapper )
    } else {
      this._config.resetSetting(property, newVal )
      this._ref.value++
    }

  }

}


/** 
 * @param {*} themeObj 
 * convert a theme object into a wrapper that exposes a refresh function 
 * and also contains a reactive ref.
 * 
 * Do the same to the array of child themes. We have to do that so
 * the when you assign a new object to a child theme with setProp,
 * it will know to tick up the reactive ref assigned to that particular
 * child theme.
 * 
 */
const themerHandleTheme = (themeObj, _arrOfChildThemePaths) => {
  //If it's already been given this treatment, then let it pass.
  if(!themeObj._isWrapper) { 
    const themeContainer = new ThemeWrapper(themeObj)
    return themeContainer
  } else {
    return themeObj
  }
}


/**
 * @param { 
 *   props,          Required. The props from the component. "theme" is required. Other props may override the theme. 
 *   defaultConfig,  Required. The default Config (kit.utils.Config) object  
 *   postSkin,       Optional. Callback for after a reskin has happened (e.g. refreshing event listeners)
 *   context         Optional. The context argument from the setup function (if present, will expose the reskin function)
 * }
 * 
 * @returns { 
 *   getProp, 
 *   setProp,
 *   reskin
 * }
 * 
 * This will ingest the properties as well as a default config object.
 * This is for use inside a themable component and returns an 
 * object with getProp, setProp and reskin.
 * 
 */
const themerHandleProps = ({ props, defaultConfig, postSkin, context }) => {

  //config variable that the getProp uses.
  //let themeConfig = props.theme._config
  //We can just use the defaultConfig if there really is no theme passed through 
  let propsOrDefaultTheme = props.theme || new ThemeWrapper(defaultConfig)

  //if the props theme that was passed through is just a plain object, then
  //we're going to turn it into a full reactive theme object.
  if(!propsOrDefaultTheme._isWrapper) {
    propsOrDefaultTheme = new ThemeWrapper(propsOrDefaultTheme)
  }

  let themeConfig = propsOrDefaultTheme._config

  let propsConfig = new Config(props)

  //the reactive ref for changes to this theme
  //This is so that changes made with the getProp function will propagate
  //through the reactivity and trigger a refresh
  const localReskin = ref(1)

  //This is so that changes made to the theme wrapper from outside 
  //the component will propagate through the reactivity and trigger a refresh.
  const parentReskin = propsOrDefaultTheme._ref

  if(!propsOrDefaultTheme._isWrapper) {
    throw new Error("Unknown error. Theme has no reactive ref.")
  }



  /**
   * 
   * @param {String.} property. Required. The name of the property. Can be dot-delimited to point to a deep property in the theme
   * @param {String. MERGE_CLASS, MERGE_STYLE or THEME_TYPE} mergeOrTypeOption. Optional
   * 
   * 
   * @returns The property value, respecting the merge option or the chain of precedence
   * i.e. props, then theme, then default options.
   * 
   * If mergeOrTypeOption is left out, then this will behave respecting the chain of precendence,
   * first attempting to retrieve the value from the props, then from the theme, then from the 
   * default options.
   * 
   * If mergeOrTypeOption is MERGE_CLASS or MERGE_STYLE, then this will assume that the property points to a string
   * in the theme/props and in that case it will merge the property from the props 
   * behind the property from the theme in a single string. In addition, if merge is
   * "style" then this will make sure that the two different merged strings are separated
   * with a semicolon ";" in keeping with the syntax of css style strings.
   * 
   * If mergeOrTypeOption is THEME_TYPE, then this will retrieve the subtheme, respecting
   * the chain of precedence, and convert to a theme wrapper if needs be.
   * 
   * Note, if the setProp was called with the 'force' flag, then this will return that value.
   * 
   */
  const getProp = (property, mergeOrTypeOption, debugCode) => {

    if(mergeOrTypeOption && !mergeOrTypeOptionTypes[mergeOrTypeOption.trim()]) {
      throw new Error("Unknown option: "+mergeOrTypeOption+" "+JSON.stringify(mergeOrTypeOptionTypes))
    }

    //this line makes it reactive, because we're consuming these *Reskin
    //reactive refs. Vue keeps track of these, and computed properties that consume
    //get prop will be triggered by calls to setProp.
    if(parentReskin.value && localReskin.value) {

      const fromTheme = themeConfig.getSetting(property)

      //If we're supposed to merge the style or class, then merge
      if(mergeOrTypeOption == MERGE_STYLE || mergeOrTypeOption == MERGE_CLASS) {
        let mergeStart = fromTheme || defaultConfig.getSetting(property)
        const mergeEnd = propsConfig.getSetting(property) || ""
        if(mergeOrTypeOption == MERGE_STYLE) {
          mergeStart = endChar(mergeStart, ";")
        } 
        return `${mergeStart} ${mergeEnd}`.trim()
      }

      //If it's a theme, then what we're going to do is see if  
      //we need to convert it to a theme-wrapper before sending it along,
      //And we're going to respect the chain of precedence.
      if(mergeOrTypeOption == THEME_TYPE) {

        //respect the chain of precedence. Return the value from the props if there is one
        const fromProps = propsConfig.getSetting(property)
        if(fromProps) {
          if(!fromProps._isWrapper) {
            const wrapper = new ThemeWrapper(fromProps)
            propsConfig.resetSetting(property, wrapper)
            return wrapper
          } else {
            return fromProps
          }
        }   

        //the next one is the value from the theme. return it if there is one
        if(fromTheme) {
          if(!fromTheme._isWrapper) {
            const wrapper = new ThemeWrapper(fromTheme)
            themeConfig.resetSetting(property, wrapper)
            return wrapper
          } else {
            return fromTheme
          }
        }

        //lastly, return the default value if there is one.
        const fromDefault = defaultConfig.getSetting(property)

        if(fromDefault) {
          if(!fromDefault._isWrapper) {
            const wrapper = new ThemeWrapper(fromDefault)
            defaultConfig.resetSetting(property, wrapper)
            return wrapper
          } else {
            return fromDefault
          }
        }     

      } 
      
      //Else, just return the property.
      else {

        //We do this so that falsy values from the theme can override truthy default values.
        const r1 = propsConfig.getSetting(property)
        const r2 = defaultConfig.getSetting(property)

        if(r1 !== undefined && r1 !== null) {
          return r1
        } else 
        if(fromTheme !== undefined && fromTheme !== null) {
          return fromTheme
        } else 
        if(r2 !== undefined && r2 !== null) {
          return r2
        } else {
          return null
        }

      }
    }
  }

  //Set a property, used for re-theming. 
  //If it's a subtheme, then you have to pass true to isTheme.
  /**
   * 
   * @param {String} property. Required. The name of the property. Can be dot-delimited to point to a deep property in the theme 
   * @param {Mixed} newVal. Required. The value to set the property to. 
   * @param {*} isTheme. Optional. Default is false. Is this a sub-theme that we're setting?
   * @param {*} force. Optional. Default is false. Do we want to force an update?
   *    So here's the deal with the force flag. There's a chain of precedence. First props, then theme, then
   *    defaults. If you leave out force, then it will set it on theme, which is just fine, but if the 
   *    props override it, then it will have no effect. The getProp function will always defer to the props.
   *    So using the 'force' option will just set it on the props, effectively making it that highest priority.
   */
  const setProp = (property, newVal, isTheme, force) => {

    if(newVal && newVal._isWrapper) {
      throw new Error("Error: setProp isn't meant to be used this way. You don't pass in ThemeWrapper objects")
    }

    //Select the proper configuration object on which to set the property and value.
    //If "force", is true, then it's going to set it on the props configuration, which 
    //will have the effect "forcing" a refresh, because the propsConfig is the highest
    //priority in the chain of precedence, meaning that it will be the property that 
    //gets read out by getProp no matter what. This is handy so that we can update
    //ui objects on the fly.
    const configObj = force ? propsConfig : themeConfig

    //If we're fetching a theme property, then 
    //then we're going to check to see if the theme has been converted to a 
    //wrapper yet. If it hasn't, then we can just reset the property 
    //like normal. But if it has been converted, then we're going to 
    //reset it. We're going to assume that the newVal is a new theme 
    //object.
    const nT = getProp(property, null, "584838jdi")
    
    if(nT && (nT._isWrapper || isTheme)) {
      nT.resetConfig(newVal)
    } else 
    if((!nT || !nT._isWrapper) && isTheme) {
      const wrapper = new ThemeWrapper(newVal)
      configObj.resetSetting(property, wrapper )
      localReskin.value++
    } else {

      configObj.resetSetting(property, newVal )

      localReskin.value++
    }

  }


  /**
   * This will apply a batch of alterations to the theme, and
   * call an optional callback afterwards, which is handy for refreshing event listeners. 
   * 
   * @param {Array<{key, value, type, force}>} skinItems. Required.
   *     An array of items to pass to the setProp.
   *  
   */
  const reskin = async(skinItems) => {    
    for(var i=0; i<skinItems.length; i++) {
      const {key, value, type, force} = skinItems[i] 
      setProp(key, value, type, force)
    }

    if(postSkin) {
      await nextTick()
      postSkin(getProp, setProp)
    }
  }

  //if context was passed in, then expose the reskin function.
  if(context) {
    context.expose({reskin})
  }

 
  return { getProp, setProp, reskin }

}


/**
 * @param {*} argsObj 
 * Either a theme object, or an object with props and defaultConfig.
 * 
 * If you're in a parent component and you need to create a child component with a theme,
 * you pass the theme objects through this function.
 * 
 * const themer = inject("themer")
 * const myTheme = themer(theCoolTheme)
 * 
 * And you pass myTheme to the themeable component:
 * 
 * <MyThing :theme="myTheme">
 * 
 * In your setup function, if you need to change the theme in child component,
 * you call the setProp:
 * 
 * watch(prefetch, async(newVal) => {
 *  
 *  if(newVal) {
 *    await nextTick()
 *    theme.setProp('backgroundColor', prefetch.coolData.backgroundColor) 
 *  }
 * 
 * })
 * 
 * Another trick. You can pass child themes. So let's say that you have a theme
 * for a lazy-img inside your main theme at the location: lazyImgTheme. 
 * Do it like this:
 * :theme="getProp('lazyImgTheme', 'theme')"
 * 
 *  <LazyImg v-if="getProp('activeImage')" 
 *     :src="image" 
 *     :theme="getProp('lazyImgTheme', 'theme')"
 *     :alt="getProp('imageAriaLabel')"
 *     :class="mergeClassesTheme('sb', getProp('imageClasses'))" 
 *     :style="getProp('imageStyle')"/>
 * 
 * @returns 
 */
export const themer = (argsObj, argsObj2) => {
  if(!argsObj.props) {
    return themerHandleTheme(argsObj, argsObj2)
  } else {
    return themerHandleProps(argsObj)
  }
}



/**
 * The factory for provide
 * 
 */
export const themerFactory = () => {
  const themerMethod = (obj1, obj2) => {
    const themerOutput = themer(obj1, obj2)
    return themerOutput
  }
  return themerMethod
}



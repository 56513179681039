
<script>
import { defineComponent, ref, computed, inject } from 'vue'
import Config from '@kit/utils/Config'


const defaultImgConfig = new Config({
  loader: {
    wheel: "Spinner-04s-200px",
    fromProject: false,
  },
  style: "",
  class: "",
  loadingStyle: "object-fit:contain; width:100%; height:auto; padding: 20px",
  loadingClass: "",
  background: false,
})


const defaultDivConfig = new Config({
  loader: {
    wheel: "Spinner-04s-200px",
    fromProject: false,
  },
  style: "background-size:cover; background-repeat:no-repeat; background-position:center;",
  class: "",
  loadingStyle: "background-size:150px 150px; background-repeat:no-repeat; background-position:center;",
  loadingClass: "",
  background: true,
})


export default defineComponent({
  name: "ParallaxLoader",
  props: {

    //The theme
    "theme": { required: false },

    //The theme properties that can be overridden.
    "style": { required: false },
    "class": { required: false },

    //loadingStyle and loadingClass will both default to style and class if they're not included.
    "loadingStyle": { required: false, default: (props) => props.style },
    "loadingClass": { required: false, default: (props) => props.class },

    //the background theme property
    "background": { required: false, default: false },

    //the aria label
    "ariaLabel": { required: false, default:""},
  },

  setup(props) {
    
    const root = ref(null) 
    const loaded = ref(false) 

    let loaderURL = ""

    const themer = inject("themer")

    const defaultConfig = props.background ? defaultDivConfig : defaultImgConfig

    const { getProp, setProp } = themer({props, defaultConfig })

    // const enteredIntoView = async() => {
    //   if(!loaded.value && inBrowser) {
    //     await loadImg()
    //     const el = root.value
    //     //loaded.value = true  /////
    //     //context.emit("lazyImgLoaded")
    //     el.style.opacity = 0;
    //     animate({targets:el, opacity:1, easing:"linear", duration:300 })
    //   }
    // }

    const loaderSrc_ = computed(() => {
      const gif = getProp("loader.wheel")
      if(getProp('loader.fromProject')) {
        require(`@project/assets/images/spinners/${gif}.gif`);
      } else {
        require(`@kit/assets/images/spinners/${gif}.gif`);
      }
      loaderURL = `/assets/${gif}.gif`
      return loaderURL
    })


    const imgSrc_ = computed(() => {
      return loaderSrc_.value
    })

    const style_ = computed(() => {
      return `background-image:url('${imgSrc_.value}'); ${getProp("loadingStyle", "style")}`
    })

    const class_ = computed(() => {
      return getProp("loadingClass")
    })
    
    return { root, imgSrc_, loaderSrc_, style_, class_, getProp, loaded }
  },
  emits: ["lazyImgLoaded"]
});
</script>

<style scoped>
  img {
    box-sizing: border-box;
  }
</style>

<template>
  <div :style="style_" :class="class_" ref="root" :aria-label="getProp('ariaLabel')"><slot></slot></div>
</template>
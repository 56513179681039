import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory
} from 'vue-router'
import { routes } from '@project/routes'
import modifyRouter from '@project/modify/router.js'
import { dispatchEvent } from '@kit/utils/EventBus'
import { incrementNav } from '@kit/utils/Hydrate'
import { gaPageView } from '@kit/utils/Analytics'
import { inBrowser } from '@/kit/utils/EnvironmentHelper'
import Parallax from '@/kit/utils/Parallax'
import { store } from './store'
import { isLoggedIn, AUTH_REDIRECT_AFTER_LOGIN, AUTH_TYPE_WPJWT, AUTH_LOGIN_STATE_COLD } from '@/kit/utils/auth/Auth'


let _router = null

export function createRouter(hydration) {
  if(!_router) {

    routes.push({
      name:"404",
      path: '/404',
      component: () => import(/* webpackChunkName: "404" */'@project/404.vue')
    })

    //the very last thing is the 404 catch-all
    routes.push({
      name:"not-found",
      path: '/:pathMatch(.*)*',
      component: () => import(/* webpackChunkName: "not-found" */'@project/404.vue')
    })

    if(typeof window == 'undefined') {
      _router = _createRouter({
        // use appropriate history implementation for server/client
        history: createMemoryHistory(),
        routes,
      })
    } else {
      _router = _createRouter({
        // use appropriate history implementation for server/client
        history: createWebHistory(),
        routes,
      })
    }


    _router.afterEach(({fullPath}, _from) => {
      gaPageView(fullPath)

      //upon navigation, we're going to force a refresh of the parallax effects
      if(inBrowser) {
        Parallax.forceRefresh()
      }
    })

    _router.beforeEach(async(to, from, next) => {

      //this will increment an index in our meta-manager so that it know what meta-information 
      //is the most up-to-date.
      incrementNav(hydration)

      let resetScrollPosition = true;
  
        //if we're switching between two urls where they both
        //belong to different sections of the page, then don't 
        //reset the scrolling.
        //There's some pages where the url parameters are linked to multiple sections of the page, 
        //like a paginated table. We want the url to change along with the page number as we 
        //page back and forth, but obviously we don't want to scroll to the top of the page each
        //time we hit the back and forward button on the paginated table.
        if(
          to.meta.paramsMappedToMultipleSections 
          && from.meta.paramsMappedToMultipleSections
          && to.name == from.name) {
          resetScrollPosition = false
        }
  
      dispatchEvent("navigateToNewPage", { resetScrollPosition });

      //If the logged in state hasn't been tested yet, then we're going to
      //test right here.
      let coldIsLoggedIn = false
      if(store.state.loggedIn == AUTH_LOGIN_STATE_COLD) {
        coldIsLoggedIn = await isLoggedIn()
      }

      //If the user is trying to go to the login page, but they're already
      //logged in, then we're going to just redirect them to the home page
      if(to.path == '/login') {

        const loggedIn = coldIsLoggedIn || await isLoggedIn()

        if(loggedIn) {
          next("/")
        } else {
          next()
        }

      } else 

      // //If there is an auth, then we're going to check to see if the user
      // //is logged in. If they're logged in, then send them along.
      if(to.meta.auth) {
        const loggedIn = coldIsLoggedIn || await isLoggedIn()

        if(!inBrowser || loggedIn) {
          next()
        } else {
    
          //Else, they're not logged in, so we're going to save whatever
          //page they were on their way to see and then we're going to
          //redirect them to the login.
          if(inBrowser) {
            window.localStorage.setItem(AUTH_REDIRECT_AFTER_LOGIN, to.path)
          }

          next("/login")
        }
      } else {
        next()
      }
    })

    modifyRouter(_router)
  }
  return _router
}